export const dev_config = {
    authDomain: "https://grihadevauth.paloalto-soft.com",
    clientId: "orOuBgSYFx45znJxfC3xgTWjaVpXSFoD",
    apiDomain: "https://wivqam17l7.execute-api.ap-south-1.amazonaws.com/dev",
    // apiDomain:"https://e7wmcmv9n3.execute-api.ap-south-1.amazonaws.com/dev",
    version: "G003V001",
    app: "svagriha",
    svagriha_url: 'https://dev2svagriha.paloalto-soft.com',
    griha_url: 'https://dev2griha.paloalto-soft.com',
    old_griha_base: 'http://124.123.18.67:55443/grihaintegration',
    backRedirection: {
        admin: '',
        client: '',
        coordinator: '',
    },
    audience: "https://dev-5onb5ctiywl2tjy2.us.auth0.com/api/v2/",
    pushNotiPublicKey:'BEzOtKR25BhDnJKoSL2zZweof4shJNieub14khZ8_AknTetdkuK23aWYRECiydc2Nfbkwx-mo3aj2Nu4etecAR4',
    googleMapKey:'AIzaSyChGSLZwl_SxgvOlQ4wwDjhmBs2xT5304E',
    myfreshworks:'https://griha.myfreshworks.com/login/auth/1729753586214?client_id=451980303908285122&redirect_uri=https%3A%2F%2Fpaloaltosoft.freshdesk.com%2Ffreshid%2Fcustomer_authorize_callback%3Fhd%3Dpaloaltosoft.freshdesk.com'
}
