import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-preview-project',
  templateUrl: './preview-project.component.html',
  styleUrl: './preview-project.component.scss'
})
export class PreviewProjectComponent {
  safePdfUrl: SafeResourceUrl;
  zoom: number = 1;
  constructor(@Inject(MAT_DIALOG_DATA) public data: { pdfUrl: string },private sanitizer: DomSanitizer) {
    this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.pdfUrl);
  }

  onClose(): void {
    // Logic to close the dialog
    
  }
}
