export class variableConstantFile {
  public static svagriha_app_code = 'G001V001';
  public static schoolgriha_app_code = 'G002V001';

  public static conditionLengthSiteArea = 'SITEAREA';
  public static svagriha_criteria1 = 'G001V001S001C001';
  public static svagriha_criteria2 = 'G001V001S001C002';
  public static svagriha_criteria3 = 'G001V001S001C003';
  public static svagriha_criteria3_appraisal1 = 'G001V001S001C003A001';
  public static svagriha_criteria3_appraisal2 = 'G001V001S001C003A002';
  public static svagriha_criteria3_appraisal2_field1 =
    'G001V001S001C003A002F001';
  public static svagriha_criteria3_appraisal2_field1_subfield1 =
    'G001V001S001C003A002F001D001';
  public static svagriha_criteria3_appraisal2_field1_subfield2 =
    'G001V001S001C003A002F001D002';
  public static svagriha_criteria3_appraisal2_field2 =
    'G001V001S001C003A002F002';
  public static svagriha_criteria3_appraisal2_field2_subfield1 =
    'G001V001S001C003A002F002D001';
  public static svagriha_criteria3_appraisal2_field3 =
    'G001V001S001C003A002F003';
  public static svagriha_criteria3_appraisal2_field3_subfield1 =
    'G001V001S001C003A002F003D001';
  public static svagriha_criteria3_appraisal2_field3_subfield2 =
    'G001V001S001C003A002F003D002';
  public static svagriha_criteria3_appraisal2_field3_subfield3 =
    'G001V001S001C003A002F003D003';
  public static svagriha_criteria3_appraisal2_field3_subfield3_subsubfield1 =
    'G001V001S001C003A002F003D003O001';
  public static svagriha_criteria3_appraisal2_field3_subfield3_subsubfield2 =
    'G001V001S001C003A002F003D003O002';
  public static svagriha_criteria4 = 'G001V001S002C004';
  public static svagriha_criteria5 = 'G001V001S002C005';
  public static svagriha_criteria6 = 'G001V001S002C006';
  public static svagriha_criteria7 = 'G001V001S003C007';
  public static svagriha_criteria8 = 'G001V001S003C008';
  public static svagriha_criteria9 = 'G001V001S004C009';
  public static svagriha_criteria10 = 'G001V001S005C010';
  public static svagriha_criteria11 = 'G001V001S005C011';
  public static svagriha_criteria11_appraisal1 = 'G001V001S005C011A001';
  public static svagriha_criteria11_appraisal1_field1 =
    'G001V001S005C011A001F001';
  public static svagriha_criteria11_appraisal1_field1_subfield1 =
    'G001V001S005C011A001F001D001';
  public static svagriha_criteria11_appraisal1_field1_subfield2 =
    'G001V001S005C011A001F001D002';
  public static svagriha_criteria11_appraisal1_field1_subfield3 =
    'G001V001S005C011A001F001D003';
  public static svagriha_criteria11_appraisal1_field1_subfield4 =
    'G001V001S005C011A001F001D004';
  public static svagriha_criteria11_appraisal1_field1_subfield5 =
    'G001V001S005C011A001F001D005';
  public static svagriha_criteria11_appraisal1_field2 =
    'G001V001S005C011A001F002';
  public static svagriha_criteria11_appraisal1_field2_subfield6 =
    'G001V001S005C011A001F002D006';
  public static svagriha_criteria11_appraisal1_field2_subfield9 =
    'G001V001S005C011A001F002D009';
  public static svagriha_criteria11_appraisal1_field3 =
    'G001V001S005C011A001F003';
  public static svagriha_criteria11_appraisal1_field3_subfield1 =
    'G001V001S005C011A001F003D001';
  public static svagriha_criteria11_appraisal1_field3_subfield2 =
    'G001V001S005C011A001F003D002';
  public static svagriha_criteria12 = 'G001V001S006C012';
  public static svagriha_criteria13 = 'G001V001S007C013';
  public static svagriha_criteria14 = 'G001V001S008C014';
  public static svagriha_criteria1_appraisal2 = 'G001V001S001C001A002';
  public static svagriha_criteria1_appraisal2_field7 =
    'G001V001S001C001A002F007';
  public static svagriha_criteria1_appraisal2_field7_subfield2 =
    'G001V001S001C001A002F007D002';
  public static svagriha_criteria1_appraisal3 = 'G001V001S001C001A003';
  public static svagriha_criteria1_appraisal3_field1 =
    'G001V001S001C001A003F001';
  public static svagriha_criteria1_appraisal3_field2 =
    'G001V001S001C001A003F002';
  public static svagriha_criteria1_appraisal4 = 'G001V001S001C001A004';
  public static svagriha_criteria1_appraisal5 = 'G001V001S001C001A005';
  public static svagriha_criteria3_appraisal1_field1_subfield1 =
    'G001V001S001C003A001F001D001';
  public static svagriha_criteria3_appraisal1_field1_subfield2 =
    'G001V001S001C003A001F001D002';
  public static svagriha_criteria3_appraisal1_field1_subfield3 =
    'G001V001S001C003A001F001D003';
  public static svagriha_criteria3_appraisal1_field1_subfield4 =
    'G001V001S001C003A001F001D004';
  public static svagriha_criteria3_appraisal1_field1_subfield5 =
    'G001V001S001C003A001F001D005';
  public static svagriha_criteria3_appraisal1_field1_subfield6 =
    'G001V001S001C003A001F001D006';
  public static svagriha_criteria3_appraisal1_field1_subfield7 =
    'G001V001S001C003A001F001D007';
  public static svagriha_criteria3_appraisal1_field1_subfield8 =
    'G001V001S001C003A001F001D008';
  public static svagriha_criteria3_appraisal1_field1_subfield9 =
    'G001V001S001C003A001F001D009';
  public static svagriha_criteria3_appraisal1_field1_subfield10 =
    'G001V001S001C003A001F001D010';
  public static svagriha_criteria3_appraisal1_field1_subfield11 =
    'G001V001S001C003A001F001D011';
  public static svagriha_criteria3_appraisal1_field1_subfield12 =
    'G001V001S001C003A001F001D012';
  public static svagriha_criteria3_appraisal1_field1_subfield13 =
    'G001V001S001C003A001F001D013';
  public static svagriha_criteria3_appraisal1_field1_subfield14 =
    'G001V001S001C003A001F001D014';
  public static svagriha_criteria3_appraisal1_field1_subfield15 =
    'G001V001S001C003A001F001D015';
  public static svagriha_criteria3_appraisal1_field1_subfield16 =
    'G001V001S001C003A001F001D016';
  public static svagriha_criteria3_appraisal1_field1_subfield17 =
    'G001V001S001C003A001F001D017';
  public static svagriha_criteria3_appraisal1_field1_subfield18 =
    'G001V001S001C003A001F001D018';
  public static svagriha_criteria3_appraisal1_field2_subfield1 =
    'G001V001S001C003A001F002D001';
  public static svagriha_criteria3_appraisal1_field2_subfield2 =
    'G001V001S001C003A001F002D002';
  public static svagriha_criteria3_appraisal1_field2_subfield3 =
    'G001V001S001C003A001F002D003';
  public static svagriha_criteria3_appraisal1_field2_subfield4 =
    'G001V001S001C003A001F002D004';
  public static svagriha_criteria3_appraisal1_field2_subfield5 =
    'G001V001S001C003A001F002D005';
  public static svagriha_criteria3_appraisal1_field2_subfield6 =
    'G001V001S001C003A001F002D006';
  public static svagriha_criteria3_appraisal1_field2_subfield7 =
    'G001V001S001C003A001F002D007';
  public static svagriha_criteria3_appraisal1_field2_subfield8 =
    'G001V001S001C003A001F002D008';
  public static svagriha_criteria3_appraisal1_field2_subfield9 =
    'G001V001S001C003A001F002D009';
  public static svagriha_criteria3_appraisal1_field2_subfield10 =
    'G001V001S001C003A001F002D010';
  public static svagriha_criteria3_appraisal1_field2_subfield11 =
    'G001V001S001C003A001F002D011';
  public static svagriha_criteria3_appraisal1_field2_subfield12 =
    'G001V001S001C003A001F002D012';
  public static svagriha_criteria3_appraisal1_field2_subfield13 =
    'G001V001S001C003A001F002D013';
  public static svagriha_criteria3_appraisal1_field2_subfield14 =
    'G001V001S001C003A001F002D014';
  public static svagriha_criteria3_appraisal1_field2_subfield15 =
    'G001V001S001C003A001F002D015';
  public static svagriha_criteria3_appraisal1_field2_subfield16 =
    'G001V001S001C003A001F002D016';
  public static svagriha_criteria3_appraisal1_field2_subfield17 =
    'G001V001S001C003A001F002D017';
  public static svagriha_criteria3_appraisal1_field2_subfield18 =
    'G001V001S001C003A001F002D018';
  public static svagriha_criteria3_appraisal1_field3_subfield1 =
    'G001V001S001C003A001F003D001';
  public static svagriha_criteria3_appraisal1_field3_subfield2 =
    'G001V001S001C003A001F003D002';
  public static svagriha_criteria3_appraisal1_field3_subfield3 =
    'G001V001S001C003A001F003D003';
  public static svagriha_criteria3_appraisal1_field3_subfield4 =
    'G001V001S001C003A001F003D004';
  public static svagriha_criteria3_appraisal1_field3_subfield5 =
    'G001V001S001C003A001F003D005';
  public static svagriha_criteria3_appraisal1_field3_subfield6 =
    'G001V001S001C003A001F003D006';
  public static svagriha_criteria3_appraisal1_field3_subfield7 =
    'G001V001S001C003A001F003D007';
  public static svagriha_criteria3_appraisal1_field3_subfield8 =
    'G001V001S001C003A001F003D008';
  public static svagriha_criteria3_appraisal1_field3_subfield9 =
    'G001V001S001C003A001F003D009';
  public static svagriha_criteria3_appraisal1_field3_subfield10 =
    'G001V001S001C003A001F003D010';
  public static svagriha_criteria3_appraisal1_field3_subfield11 =
    'G001V001S001C003A001F003D011';
  public static svagriha_criteria3_appraisal1_field3_subfield12 =
    'G001V001S001C003A001F003D012';
  public static svagriha_criteria3_appraisal1_field3_subfield13 =
    'G001V001S001C003A001F003D013';
  public static svagriha_criteria3_appraisal1_field3_subfield14 =
    'G001V001S001C003A001F003D014';
  public static svagriha_criteria3_appraisal1_field3_subfield15 =
    'G001V001S001C003A001F003D015';
  public static svagriha_criteria3_appraisal1_field3_subfield16 =
    'G001V001S001C003A001F003D016';
  public static svagriha_criteria3_appraisal1_field3_subfield17 =
    'G001V001S001C003A001F003D017';
  public static svagriha_criteria3_appraisal1_field3_subfield18 =
    'G001V001S001C003A001F003D018';
  public static svagriha_criteria3_appraisal1_field4_subfield1 =
    'G001V001S001C003A001F005D001';
  public static svagriha_criteria3_appraisal1_field4_subfield2 =
    'G001V001S001C003A001F005D002';
  public static svagriha_criteria3_appraisal1_field4_subfield3 =
    'G001V001S001C003A001F005D003';
  public static svagriha_criteria3_appraisal1_field4_subfield4 =
    'G001V001S001C003A001F005D004';
  public static svagriha_criteria3_appraisal1_field4_subfield5 =
    'G001V001S001C003A001F004D005';
  public static svagriha_criteria3_appraisal1_field4_subfield6 =
    'G001V001S001C003A001F004D006';
  public static svagriha_criteria3_appraisal1_field4_subfield7 =
    'G001V001S001C003A001F004D007';
  public static svagriha_criteria3_appraisal1_field4_subfield8 =
    'G001V001S001C003A001F004D008';
  public static svagriha_criteria3_appraisal1_field4_subfield9 =
    'G001V001S001C003A001F004D009';
  public static svagriha_criteria3_appraisal1_field4_subfield10 =
    'G001V001S001C003A001F004D010';
  public static svagriha_criteria3_appraisal1_field4_subfield11 =
    'G001V001S001C003A001F004D011';
  public static svagriha_criteria3_appraisal1_field4_subfield12 =
    'G001V001S001C003A001F004D012';
  public static svagriha_criteria3_appraisal1_field4_subfield13 =
    'G001V001S001C003A001F004D013';
  public static svagriha_criteria3_appraisal1_field4_subfield14 =
    'G001V001S001C003A001F004D014';
  public static svagriha_criteria3_appraisal1_field4_subfield15 =
    'G001V001S001C003A001F004D015';
  public static svagriha_criteria3_appraisal1_field4_subfield16 =
    'G001V001S001C003A001F004D016';
  public static svagriha_criteria3_appraisal1_field4_subfield17 =
    'G001V001S001C003A001F004D017';
  public static svagriha_criteria3_appraisal1_field4_subfield18 =
    'G001V001S001C003A001F004D018';
  public static svagriha_criteria3_appraisal1_field5_subfield1 =
    'G001V001S001C003A001F005D001';
  public static svagriha_criteria3_appraisal1_field5_subfield2 =
    'G001V001S001C003A001F005D002';
  public static svagriha_criteria3_appraisal1_field5_subfield3 =
    'G001V001S001C003A001F005D003';
  public static svagriha_criteria3_appraisal1_field5_subfield4 =
    'G001V001S001C003A001F005D004';
  public static svagriha_criteria3_appraisal1_field5_subfield5 =
    'G001V001S001C003A001F005D005';
  public static svagriha_criteria3_appraisal1_field5_subfield6 =
    'G001V001S001C003A001F005D006';
  public static svagriha_criteria3_appraisal1_field5_subfield7 =
    'G001V001S001C003A001F005D007';
  public static svagriha_criteria3_appraisal1_field5_subfield8 =
    'G001V001S001C003A001F005D008';
  public static svagriha_criteria3_appraisal1_field5_subfield9 =
    'G001V001S001C003A001F005D009';
  public static svagriha_criteria3_appraisal1_field5_subfield10 =
    'G001V001S001C003A001F005D010';
  public static svagriha_criteria3_appraisal1_field5_subfield11 =
    'G001V001S001C003A001F005D011';
  public static svagriha_criteria3_appraisal1_field5_subfield12 =
    'G001V001S001C003A001F005D012';
  public static svagriha_criteria3_appraisal1_field5_subfield13 =
    'G001V001S001C003A001F005D013';
  public static svagriha_criteria3_appraisal1_field5_subfield14 =
    'G001V001S001C003A001F005D014';
  public static svagriha_criteria3_appraisal1_field5_subfield15 =
    'G001V001S001C003A001F005D015';
  public static svagriha_criteria3_appraisal1_field5_subfield16 =
    'G001V001S001C003A001F005D016';
  public static svagriha_criteria3_appraisal1_field5_subfield17 =
    'G001V001S001C003A001F005D017';
  public static svagriha_criteria3_appraisal1_field5_subfield18 =
    'G001V001S001C003A001F005D018';
  public static svagriha_criteria3_appraisal1_field6_subfield1 =
    'G001V001S001C003A001F006D001';
  public static svagriha_criteria3_appraisal1_field6_subfield2 =
    'G001V001S001C003A001F006D002';
  public static svagriha_criteria3_appraisal1_field6_subfield3 =
    'G001V001S001C003A001F006D003';
  public static svagriha_criteria3_appraisal1_field6_subfield4 =
    'G001V001S001C003A001F006D004';
  public static svagriha_criteria3_appraisal1_field6_subfield5 =
    'G001V001S001C003A001F006D005';
  public static svagriha_criteria3_appraisal1_field6_subfield6 =
    'G001V001S001C003A001F006D006';
  public static svagriha_criteria3_appraisal1_field6_subfield7 =
    'G001V001S001C003A001F006D007';
  public static svagriha_criteria3_appraisal1_field6_subfield8 =
    'G001V001S001C003A001F006D008';
  public static svagriha_criteria3_appraisal1_field6_subfield9 =
    'G001V001S001C003A001F006D009';
  public static svagriha_criteria3_appraisal1_field6_subfield10 =
    'G001V001S001C003A001F006D010';
  public static svagriha_criteria3_appraisal1_field6_subfield11 =
    'G001V001S001C003A001F006D011';
  public static svagriha_criteria3_appraisal1_field6_subfield12 =
    'G001V001S001C003A001F006D012';
  public static svagriha_criteria3_appraisal1_field6_subfield13 =
    'G001V001S001C003A001F006D013';
  public static svagriha_criteria3_appraisal1_field6_subfield14 =
    'G001V001S001C003A001F006D014';
  public static svagriha_criteria3_appraisal1_field6_subfield15 =
    'G001V001S001C003A001F006D015';
  public static svagriha_criteria3_appraisal1_field6_subfield16 =
    'G001V001S001C003A001F006D016';
  public static svagriha_criteria3_appraisal1_field6_subfield17 =
    'G001V001S001C003A001F006D017';
  public static svagriha_criteria3_appraisal1_field6_subfield18 =
    'G001V001S001C003A001F006D018';
  public static svagriha_criteria3_appraisal1_field7_subfield1 =
    'G001V001S001C003A001F007D001';
  public static svagriha_criteria3_appraisal1_field7_subfield2 =
    'G001V001S001C003A001F007D002';
  public static svagriha_criteria3_appraisal1_field7_subfield3 =
    'G001V001S001C003A001F007D003';
  public static svagriha_criteria3_appraisal1_field7_subfield4 =
    'G001V001S001C003A001F007D004';
  public static svagriha_criteria3_appraisal1_field7_subfield5 =
    'G001V001S001C003A001F007D005';
  public static svagriha_criteria3_appraisal1_field7_subfield6 =
    'G001V001S001C003A001F007D006';
  public static svagriha_criteria3_appraisal1_field7_subfield7 =
    'G001V001S001C003A001F007D007';
  public static svagriha_criteria3_appraisal1_field7_subfield8 =
    'G001V001S001C003A001F007D008';
  public static svagriha_criteria3_appraisal1_field7_subfield9 =
    'G001V001S001C003A001F007D009';
  public static svagriha_criteria3_appraisal1_field7_subfield10 =
    'G001V001S001C003A001F007D010';
  public static svagriha_criteria3_appraisal1_field7_subfield11 =
    'G001V001S001C003A001F007D011';
  public static svagriha_criteria3_appraisal1_field7_subfield12 =
    'G001V001S001C003A001F007D012';
  public static svagriha_criteria3_appraisal1_field7_subfield13 =
    'G001V001S001C003A001F007D013';
  public static svagriha_criteria3_appraisal1_field7_subfield14 =
    'G001V001S001C003A001F007D014';
  public static svagriha_criteria3_appraisal1_field7_subfield15 =
    'G001V001S001C003A001F007D015';
  public static svagriha_criteria3_appraisal1_field7_subfield16 =
    'G001V001S001C003A001F007D016';
  public static svagriha_criteria3_appraisal1_field7_subfield17 =
    'G001V001S001C003A001F007D017';
  public static svagriha_criteria3_appraisal1_field7_subfield18 =
    'G001V001S001C003A001F007D018';
  public static svagriha_criteria3_appraisal1_field8_subfield1 =
    'G001V001S001C003A001F008D001';
  public static svagriha_criteria3_appraisal1_field8_subfield2 =
    'G001V001S001C003A001F008D002';
  public static svagriha_criteria3_appraisal1_field8_subfield3 =
    'G001V001S001C003A001F008D003';
  public static svagriha_criteria3_appraisal1_field8_subfield4 =
    'G001V001S001C003A001F008D004';
  public static svagriha_criteria3_appraisal1_field8_subfield5 =
    'G001V001S001C003A001F008D005';
  public static svagriha_criteria3_appraisal1_field8_subfield6 =
    'G001V001S001C003A001F008D006';
  public static svagriha_criteria3_appraisal1_field8_subfield7 =
    'G001V001S001C003A001F008D007';
  public static svagriha_criteria3_appraisal1_field8_subfield8 =
    'G001V001S001C003A001F008D008';
  public static svagriha_criteria3_appraisal1_field8_subfield9 =
    'G001V001S001C003A001F008D009';
  public static svagriha_criteria3_appraisal1_field8_subfield10 =
    'G001V001S001C003A001F008D010';
  public static svagriha_criteria3_appraisal1_field8_subfield11 =
    'G001V001S001C003A001F008D011';
  public static svagriha_criteria3_appraisal1_field8_subfield12 =
    'G001V001S001C003A001F008D012';
  public static svagriha_criteria3_appraisal1_field8_subfield13 =
    'G001V001S001C003A001F008D013';
  public static svagriha_criteria3_appraisal1_field8_subfield14 =
    'G001V001S001C003A001F008D014';
  public static svagriha_criteria3_appraisal1_field8_subfield15 =
    'G001V001S001C003A001F008D015';
  public static svagriha_criteria3_appraisal1_field8_subfield16 =
    'G001V001S001C003A001F008D016';
  public static svagriha_criteria3_appraisal1_field8_subfield17 =
    'G001V001S001C003A001F008D017';
  public static svagriha_criteria3_appraisal1_field8_subfield18 =
    'G001V001S001C003A001F008D018';
  public static svagriha_criteria4_appraisal1 = 'G001V001S002C004A001';
  public static svagriha_criteria4_appraisal1_fields1_option1 =
    'G001V001S002C004A001F001O001';
  public static svagriha_criteria4_appraisal2 = 'G001V001S002C004A002';
  public static svagriha_criteria5_appraisal1 = 'G001V001S002C005A001';
  public static svagriha_criteria5_appraisal2 = 'G001V001S002C005A002';
  public static svagriha_criteria5_appraisal2_field1 =
    'G001V001S002C005A002F001';
  public static svagriha_criteria5_appraisal2_field2 =
    'G001V001S002C005A002F002';
  public static svagriha_criteria5_appraisal2_field9 =
    'G001V001S002C005A002F009';
  public static svagriha_criteria5_appraisal2_field9_subfield1 =
    'G001V001S002C005A002F009O001';
  public static svagriha_criteria6_appraisal1 = 'G001V001S002C006A001';
  public static svagriha_criteria6_appraisal2 = 'G001V001S002C006A002';
  public static svagriha_criteria7_appraisal1 = 'G001V001S003C007A001';
  public static svagriha_criteria7_appraisal1_fieldCode =
    'G001V001S003C007A001F001';
  public static svagriha_criteria7_appraisal2 = 'G001V001S003C007A002';
  public static svagriha_criteria7_appraisal2_fieldCode =
    'G001V001S003C007A002F001';
  public static svagriha_criteria7_appraisal1_field1 =
    'G001V001S003C007A001F001D001';
  public static svagriha_criteria7_appraisal1_field2 =
    'G001V001S003C007A001F001D002';
  public static svagriha_criteria7_appraisal1_field3 =
    'G001V001S003C007A001F001D003';
  public static svagriha_criteria7_appraisal1_field4 =
    'G001V001S003C007A001F001D004';
  public static svagriha_criteria7_appraisal1_field5 =
    'G001V001S003C007A001F001D005';
  public static svagriha_criteria7_appraisal1_field6 =
    'G001V001S003C007A001F001D006';
  public static svagriha_criteria7_appraisal1_field7 =
    'G001V001S003C007A001F001D007';
  public static svagriha_criteria7_appraisal1_field7_subField1 =
    'G001V001S003C007A001F001D007O001';
  public static svagriha_criteria7_appraisal1_field7_subField2 =
    'G001V001S003C007A001F001D007O002';
  public static svagriha_criteria7_appraisal1_field7_subField3 =
    'G001V001S003C007A001F001D007O003';
  public static svagriha_criteria7_appraisal1_field7_subField4 =
    'G001V001S003C007A001F001D007O004';
  public static svagriha_criteria7_appraisal1_field7_subField5 =
    'G001V001S003C007A001F001D007O005';
  public static svagriha_criteria7_appraisal1_field7_subField6 =
    'G001V001S003C007A001F001D007O006';
  public static svagriha_criteria7_appraisal2_field1 =
    'G001V001S003C007A002F001D001';
  public static svagriha_criteria7_appraisal2_field2 =
    'G001V001S003C007A002F001D002';
  public static svagriha_criteria7_appraisal2_field3 =
    'G001V001S003C007A002F001D003';
  public static svagriha_criteria7_appraisal2_field4 =
    'G001V001S003C007A002F001D004';
  public static svagriha_criteria7_appraisal2_field5 =
    'G001V001S003C007A002F001D005';
  public static svagriha_criteria7_appraisal2_field6 =
    'G001V001S003C007A002F001D006';
  public static svagriha_criteria7_appraisal2_field7 =
    'G001V001S003C007A002F001D007';
  public static svagriha_criteria7_appraisal2_field6_subField1 =
    'G001V001S003C007A002F001D006O001';
  public static svagriha_criteria7_appraisal2_field6_subField2 =
    'G001V001S003C007A002F001D006O002';
  public static svagriha_criteria7_appraisal2_field6_subField3 =
    'G001V001S003C007A002F001D006O003';
  public static svagriha_criteria7_appraisal2_field6_subField4 =
    'G001V001S003C007A002F001D006O004';
  public static svagriha_criteria7_appraisal2_field6_subField5 =
    'G001V001S003C007A002F001D006O005';
  public static svagriha_criteria7_appraisal2_field6_subField6 =
    'G001V001S003C007A002F001D006O006';
  public static svagriha_criteria7_appraisal3 = 'G001V001S003C007A003';
  public static svagriha_criteria7_appraisal3_field1 =
    'G001V001S003C007A003F001';
  public static svagriha_criteria7_appraisal3_field2 =
    'G001V001S003C007A003F002';
  public static svagriha_criteria7_appraisal3_field4 =
    'G001V001S003C007A003F004';
  public static svagriha_criteria7_appraisal3_field5 =
    'G001V001S003C007A003F005';
  public static svagriha_criteria7_appraisal3_field7 =
    'G001V001S003C007A003F007';
  public static svagriha_criteria7_appraisal3_field8 =
    'G001V001S003C007A003F008';
  public static svagriha_criteria7_appraisal3_field10 =
    'G001V001S003C007A003F010';
  public static svagriha_criteria7_appraisal3_field11 =
    'G001V001S003C007A003F011';
  public static svagriha_criteria7_appraisal3_field13 =
    'G001V001S003C007A003F013';
  public static svagriha_criteria8_appraisal1 = 'G001V001S003C008A001';
  public static svagriha_criteria8_appraisal1_field1 =
    'G001V001S003C008A001F001';
  public static svagriha_criteria8_appraisal1_field2 =
    'G001V001S003C008A001F002';
  public static svagriha_criteria8_appraisal1_field3 =
    'G001V001S003C008A001F003';
  public static svagriha_criteria9_appraisal1 = 'G001V001S004C009A001';
  public static svagriha_criteria9_appraisal1_field1 =
    'G001V001S004C009A001F001';
  public static svagriha_criteria9_appraisal1_field1_subField1 =
    'G001V001S004C009A001F001D001';
  public static svagriha_criteria9_appraisal1_field1_subField2 =
    'G001V001S004C009A001F001D002';
  public static svagriha_criteria9_appraisal1_field1_subField3 =
    'G001V001S004C009A001F001D003';
  public static svagriha_criteria9_appraisal1_field1_subField4 =
    'G001V001S004C009A001F001D004';
  public static svagriha_criteria9_appraisal1_field1_subField5 =
    'G001V001S004C009A001F001D005';
  public static svagriha_criteria9_appraisal1_field1_subField6 =
    'G001V001S004C009A001F001D006';
  public static svagriha_criteria9_appraisal2 = 'G001V001S004C009A002';
  public static svagriha_criteria9_appraisal2_field1 =
    'G001V001S004C009A002F001';
  public static svagriha_criteria9_appraisal2_field2 =
    'G001V001S004C009A002F002';
  public static svagriha_criteria9_appraisal2_field1_subField1 =
    'G001V001S004C009A002F001D001';
  public static svagriha_criteria9_appraisal2_field1_subField2 =
    'G001V001S004C009A002F001D002';
  public static svagriha_criteria9_appraisal2_field1_subField3 =
    'G001V001S004C009A002F001D003';
  public static svagriha_criteria9_appraisal2_field1_subField4 =
    'G001V001S004C009A002F001D004';
  public static svagriha_criteria9_appraisal2_field1_subField5 =
    'G001V001S004C009A002F001D005';
  public static svagriha_criteria9_appraisal2_field1_subField6 =
    'G001V001S004C009A002F001D006';
  public static svagriha_criteria9_appraisal2_field1_subField6_opt1 =
    'G001V001S004C009A002F001D006O001';
  public static svagriha_criteria9_appraisal2_field1_subField6_opt2 =
    'G001V001S004C009A002F001D006O002';
  public static svagriha_criteria9_appraisal2_field1_subField7 =
    'G001V001S004C009A002F001D007';
  public static svagriha_criteria9_appraisal2_field1_subField8 =
    'G001V001S004C009A002F001D008';
  public static svagriha_criteria9_appraisal2_field1_subField9 =
    'G001V001S004C009A002F001D009';
  public static svagriha_criteria9_appraisal2_field1_subField10 =
    'G001V001S004C009A002F001D010';
  public static svagriha_criteria9_appraisal2_field1_subField11_opt1 =
    'G001V001S004C009A002F001D011O001';
  public static svagriha_criteria9_appraisal2_field1_subField11_opt2 =
    'G001V001S004C009A002F001D011O002';
  public static svagriha_criteria9_appraisal2_field1_subField11_opt3 =
    'G001V001S004C009A002F001D011O003';
  public static svagriha_criteria9_appraisal2_field1_subField11 =
    'G001V001S004C009A002F001D011';
  public static svagriha_criteria9_appraisal2_field1_subField12 =
    'G001V001S004C009A002F001D012';
  public static svagriha_criteria9_appraisal2_field1_subField13_opt1 =
    'G001V001S004C009A002F001D013O001';
  public static svagriha_criteria9_appraisal2_field1_subField13_opt2 =
    'G001V001S004C009A002F001D013O002';
  public static svagriha_criteria9_appraisal2_field1_subField13 =
    'G001V001S004C009A002F001D013';
  public static svagriha_criteria9_appraisal2_field1_subField14 =
    'G001V001S004C009A002F001D014';
  public static svagriha_criteria9_appraisal3 = 'G001V001S004C009A003';
  public static svagriha_criteria9_appraisal4 = 'G001V001S004C009A004';
  public static svagriha_criteria9_appraisal5 = 'G001V001S004C009A005';
  public static svagriha_criteria10_appraisal1 = 'G001V001S005C010A001';
  public static svagriha_criteria10_appraisal1_field1 =
    'G001V001S005C010A001F001';
  public static svagriha_criteria10_appraisal1_field2 =
    'G001V001S005C010A001F002';
  public static svagriha_criteria10_appraisal1_field2_details1 =
    'G001V001S005C010A001F002D001';
  public static svagriha_criteria10_appraisal1_field2_details2 =
    'G001V001S005C010A001F002D002';
  public static svagriha_criteria10_appraisal1_field2_details3 =
    'G001V001S005C010A001F002D003';
  public static svagriha_criteria10_appraisal1_field2_details4 =
    'G001V001S005C010A001F002D004';
  public static svagriha_criteria10_appraisal1_field2_details5 =
    'G001V001S005C010A001F002D005';
  public static svagriha_criteria10_appraisal1_field2_details6 =
    'G001V001S005C010A001F002D006';
  public static svagriha_criteria10_appraisal1_field2_details7 =
    'G001V001S005C010A001F002D007';
  public static svagriha_criteria10_appraisal1_field2_details8 =
    'G001V001S005C010A001F002D008';
  public static svagriha_criteria10_appraisal1_field2_details9 =
    'G001V001S005C010A001F002D009';
  public static svagriha_criteria10_appraisal1_field2_details10 =
    'G001V001S005C010A001F002D010';
  public static svagriha_criteria10_appraisal1_field2_details11 =
    'G001V001S005C010A001F002D011';
  public static svagriha_criteria10_appraisal1_field2_details12 =
    'G001V001S005C010A001F002D012';
  public static svagriha_criteria10_appraisal1_field2_details13 =
    'G001V001S005C010A001F002D013';
  public static svagriha_criteria10_appraisal1_field2_details14 =
    'G001V001S005C010A001F002D014';
  public static svagriha_criteria10_appraisal1_field2_details15 =
    'G001V001S005C010A001F002D015';
  public static svagriha_criteria10_appraisal1_field2_details16 =
    'G001V001S005C010A001F002D016';
  public static svagriha_criteria10_appraisal1_field2_details17 =
    'G001V001S005C010A001F002D017';
  public static svagriha_criteria10_appraisal1_field2_details18 =
    'G001V001S005C010A001F002D018';
  public static svagriha_criteria10_appraisal1_field2_details19 =
    'G001V001S005C010A001F002D019';
  public static svagriha_criteria10_appraisal1_field2_details20 =
    'G001V001S005C010A001F002D020';
  public static svagriha_criteria10_appraisal1_field2_details21 =
    'G001V001S005C010A001F002D021';
  public static svagriha_criteria10_appraisal1_field2_details22 =
    'G001V001S005C010A001F002D022';
  public static svagriha_criteria10_appraisal1_field2_details23 =
    'G001V001S005C010A001F002D023';
  public static svagriha_criteria10_appraisal1_field2_details24 =
    'G001V001S005C010A001F002D024';
  public static svagriha_criteria10_appraisal1_field2_details25 =
    'G001V001S005C010A001F002D025';
  public static svagriha_criteria10_appraisal1_field2_details29 =
    'G001V001S005C010A001F002D029';
  public static svagriha_criteria10_appraisal1_field2_details30 =
    'G001V001S005C010A001F002D030';
  public static svagriha_criteria10_appraisal1_field2_details35 =
    'G001V001S005C010A001F002D035';
  public static svagriha_criteria10_appraisal1_field2_details36 =
    'G001V001S005C010A001F002D036';
  public static svagriha_criteria10_appraisal1_field2_details37 =
    'G001V001S005C010A001F002D037';
  public static svagriha_criteria10_appraisal1_field2_details38 =
    'G001V001S005C010A001F002D038';
  public static svagriha_criteria10_appraisal1_field2_details39 =
    'G001V001S005C010A001F002D039';
  public static svagriha_criteria10_appraisal1_field2_details40 =
    'G001V001S005C010A001F002D040';
  public static svagriha_criteria10_appraisal1_field2_details41 =
    'G001V001S005C010A001F002D041';
  public static svagriha_criteria10_appraisal1_field2_details42 =
    'G001V001S005C010A001F002D042';
  public static svagriha_criteria10_appraisal2 = 'G001V001S005C010A002';
  public static svagriha_criteria10_appraisal2_field1 =
    'G001V001S005C010A002F001';
  public static svagriha_criteria10_appraisal2_field1_subField1 =
    'G001V001S005C010A002F001D001';
  public static svagriha_criteria10_appraisal2_field1_subField2 =
    'G001V001S005C010A002F001D002';
  public static svagriha_criteria10_appraisal2_field1_subField3 =
    'G001V001S005C010A002F001D003';
  public static svagriha_criteria12_appraisal1 = 'G001V001S006C012A001';
  public static svagriha_criteria12_appraisal1_fields1_option1 =
    'G001V001S006C012A001F001O001';
  public static svagriha_criteria12_appraisal2 = 'G001V001S006C012A002';
  public static svagriha_criteria12_appraisal2_field1 =
    'G001V001S006C012A002F001';
  public static svagriha_criteria12_appraisal2_field1_subField1 =
    'G001V001S006C012A002F001D001';
  public static svagriha_criteria12_appraisal2_field1_subField2 =
    'G001V001S006C012A002F001D002';
  public static svagriha_criteria12_appraisal2_field1_subField3 =
    'G001V001S006C012A002F001D003';
  public static svagriha_criteria12_appraisal2_field1_subField4 =
    'G001V001S006C012A002F001D004';
  public static svagriha_criteria12_appraisal2_field1_subField5 =
    'G001V001S006C012A002F001D005';
  public static svagriha_criteria12_appraisal2_field1_subField6 =
    'G001V001S006C012A002F001D006';
  public static svagriha_criteria12_appraisal2_field1_subField7 =
    'G001V001S006C012A002F001D007';
  public static svagriha_criteria12_appraisal2_field1_subField8 =
    'G001V001S006C012A002F001D008';
  public static svagriha_criteria12_appraisal2_field1_subField9 =
    'G001V001S006C012A002F001D009';
  public static svagriha_criteria12_appraisal2_field1_subField10 =
    'G001V001S006C012A002F001D0010';
  public static svagriha_criteria13_appraisal1 = 'G001V001S007C013A001';
  public static svagriha_criteria13_appraisal2 = 'G001V001S007C013A002';
  public static svagriha_criteria13_appraisal3 = 'G001V001S007C013A003';
  public static svagriha_criteria13_appraisal4 = 'G001V001S007C013A004';
  public static svagriha_criteria13_appraisal2_field1 =
    'G001V001S007C013A002F001';
  public static svagriha_criteria13_appraisal2_field2 =
    'G001V001S007C013A002F002';

  public static svagriha_criteria14_appraisal1 = 'G001V001S008C014A001';

  public static svagriha_criteria14_appraisal1_field1 =
    'G001V001S008C014A001F001';
  public static svagriha_criteria14_appraisal1_field2 =
    'G001V001S008C014A001F002';
  public static svagriha_criteria14_appraisal1_field3 =
    'G001V001S008C014A001F003';
  public static svagriha_criteria14_appraisal1_field4 =
    'G001V001S008C014A001F004';

  public static svagriha_criteria3_calc1_field1 = 'G001V001S001C003A001R001';
  public static svagriha_criteria3_calc1_field2 = 'G001V001S001C003A001R002';
  public static svagriha_criteria3_calc1_field3 = 'G001V001S001C003A001R003';
  public static svagriha_criteria3_calc2_field1 = 'G001V001S001C003A002R001';

  //school-griha//

  public static schoolgriha_criteria1 = 'G002V001S001C001';
  public static schoolgriha_criteria2 = 'G002V001S001C002';
  public static schoolgriha_criteria2_appraisal1 = 'G002V001S001C002A001';
  public static schoolgriha_criteria2_appraisal1_field1 =
    'G002V001S001C002A001F001';
  public static schoolgriha_criteria2_appraisal1_field1_subfield1 =
    'G002V001S001C002A001F001D001';
  public static schoolgriha_criteria2_appraisal1_field2 =
    'G002V001S001C002A001F002';
  public static schoolgriha_criteria2_appraisal1_field2_subfield1 =
    'G002V001S001C002A001F002D001';
  public static schoolgriha_criteria2_appraisal1_field3 =
    'G002V001S001C002A001F003';
  public static schoolgriha_criteria2_appraisal1_field3_subfield1 =
    'G002V001S001C002A001F003D001';
  public static schoolgriha_criteria2_appraisal1_field4 =
    'G002V001S001C002A001F004';
  public static schoolgriha_criteria2_appraisal1_field4_subfield1 =
    'G002V001S001C002A001F004D001';
  public static schoolgriha_criteria2_appraisal1_field5 =
    'G002V001S001C002A001F005';
  public static schoolgriha_criteria2_appraisal1_field5_subfield1 =
    'G002V001S001C002A001F005D001';
  public static schoolgriha_criteria2_appraisal1_field6 =
    'G002V001S001C002A001F006';
  public static schoolgriha_criteria2_appraisal1_field6_subfield1 =
    'G002V001S001C002A001F006D001';
  public static schoolgriha_criteria2_appraisal2 = 'G002V001S001C002A002';
  public static schoolgriha_criteria2_appraisal3 = 'G002V001S001C002A003';
  public static schoolgriha_criteria2_appraisal3_field1 =
    'G002V001S001C002A003F001';
  public static schoolgriha_criteria2_appraisal3_field1_subfield1 =
    'G002V001S001C002A003F001D001';
  public static schoolgriha_criteria2_appraisal3_field2 =
    'G002V001S001C002A003F002';
  public static schoolgriha_criteria2_appraisal3_field2_subfield1 =
    'G002V001S001C002A003F002D001';
  public static schoolgriha_criteria2_appraisal3_field3 =
    'G002V001S001C002A003F003';
  public static schoolgriha_criteria2_appraisal3_field3_subfield1 =
    'G002V001S001C002A003F003D001';
  public static schoolgriha_criteria2_appraisal3_field4 =
    'G002V001S001C002A003F004';
  public static schoolgriha_criteria2_appraisal3_field4_subfield1 =
    'G002V001S001C002A003F004D001';
  public static schoolgriha_criteria2_appraisal3_field5 =
    'G002V001S001C002A003F005';
  public static schoolgriha_criteria2_appraisal3_field5_subfield1 =
    'G002V001S001C002A003F005D001';
  public static schoolgriha_criteria2_appraisal3_field6 =
    'G002V001S001C002A003F006';
  public static schoolgriha_criteria2_appraisal3_field6_subfield1 =
    'G002V001S001C002A003F006D001';
  public static schoolgriha_criteria2_appraisal4 = 'G002V001S001C002A004';
  public static schoolgriha_criteria2_appraisal4_field1 =
    'G002V001S001C002A004F001';
  public static schoolgriha_criteria2_appraisal4_field1_subfield1 =
    'G002V001S001C002A004F001D001';
  public static schoolgriha_criteria2_appraisal4_field2 =
    'G002V001S001C002A004F002';
  public static schoolgriha_criteria2_appraisal4_field2_subfield1 =
    'G002V001S001C002A004F002D001';
  public static schoolgriha_criteria2_appraisal4_field3 =
    'G002V001S001C002A004F003';
  public static schoolgriha_criteria2_appraisal4_field3_subfield1 =
    'G002V001S001C002A004F003D001';
  public static schoolgriha_criteria2_appraisal4_field4 =
    'G002V001S001C002A004F004';
  public static schoolgriha_criteria2_appraisal4_field4_subfield1 =
    'G002V001S001C002A004F004D001';
  public static schoolgriha_criteria2_appraisal4_field5 =
    'G002V001S001C002A004F005';
  public static schoolgriha_criteria2_appraisal4_field5_subfield1 =
    'G002V001S001C002A004F005D001';
  public static schoolgriha_criteria2_appraisal4_field6 =
    'G002V001S001C002A004F006';
  public static schoolgriha_criteria2_appraisal4_field6_subfield1 =
    'G002V001S001C002A004F006D001';
  public static schoolgriha_criteria2_appraisal5 = 'G002V001S001C002A005';
  public static schoolgriha_criteria2_appraisal5_field1 =
    'G002V001S001C002A005F001';
  public static schoolgriha_criteria2_appraisal5_field1_subfield1 =
    'G002V001S001C002A005F001D001';
  public static schoolgriha_criteria2_appraisal5_field2 =
    'G002V001S001C002A005F002';
  public static schoolgriha_criteria2_appraisal5_field2_subfield1 =
    'G002V001S001C002A005F002D001';
  public static schoolgriha_criteria2_appraisal5_field3 =
    'G002V001S001C002A005F003';
  public static schoolgriha_criteria2_appraisal5_field3_subfield1 =
    'G002V001S001C002A005F003D001';
  public static schoolgriha_criteria2_appraisal5_field4 =
    'G002V001S001C002A005F004';
  public static schoolgriha_criteria2_appraisal5_field4_subfield1 =
    'G002V001S001C002A005F004D001';
  public static schoolgriha_criteria2_appraisal5_field5 =
    'G002V001S001C002A005F005';
  public static schoolgriha_criteria2_appraisal5_field5_subfield1 =
    'G002V001S001C002A005F005D001';
  public static schoolgriha_criteria2_appraisal5_field6 =
    'G002V001S001C002A005F006';
  public static schoolgriha_criteria2_appraisal5_field6_subfield1 =
    'G002V001S001C002A005F006D001';
  public static schoolgriha_criteria3 = 'G002V001S001C003';
  public static schoolgriha_criteria3_appraisal1 = 'G002V001S001C003A001';
  public static schoolgriha_criteria3_appraisal1_field1 =
    'G002V001S001C003A001F001';
  public static schoolgriha_criteria3_appraisal1_field1_subfield1 =
    'G002V001S001C003A001F001D001';
  public static schoolgriha_criteria3_appraisal1_field1_subfield2 =
    'G002V001S001C003A001F001D002';
  public static schoolgriha_criteria3_appraisal1_field2 =
    'G002V001S001C003A001F002';
  public static schoolgriha_criteria3_appraisal1_field2_subfield1 =
    'G002V001S001C003A001F002D001';
  public static schoolgriha_criteria3_appraisal1_field2_subfield2 =
    'G002V001S001C003A001F002D002';
  public static schoolgriha_criteria3_appraisal1_field3 =
    'G002V001S001C003A001F003';
  public static schoolgriha_criteria3_appraisal1_field3_subfield1 =
    'G002V001S001C003A001F003D001';
  public static schoolgriha_criteria3_appraisal1_field3_subfield2 =
    'G002V001S001C003A001F003D002';
  public static schoolgriha_criteria3_appraisal1_field4 =
    'G002V001S001C003A001F004';
  public static schoolgriha_criteria3_appraisal1_field4_subfield1 =
    'G002V001S001C003A001F004D001';
  public static schoolgriha_criteria3_appraisal1_field4_subfield2 =
    'G002V001S001C003A001F004D002';
  public static schoolgriha_criteria3_appraisal1_field5 =
    'G002V001S001C003A001F005';
  public static schoolgriha_criteria3_appraisal1_field5_subfield1 =
    'G002V001S001C003A001F005D001';
  public static schoolgriha_criteria3_appraisal1_field5_subfield2 =
    'G002V001S001C003A001F005D002';
  public static schoolgriha_criteria3_appraisal1_field6 =
    'G002V001S001C003A001F006';
  public static schoolgriha_criteria3_appraisal1_field6_subfield1 =
    'G002V001S001C003A001F006D001';
  public static schoolgriha_criteria3_appraisal1_field6_subfield2 =
    'G002V001S001C003A001F006D002';
  public static schoolgriha_criteria3_appraisal2 = 'G002V001S001C003A002';
  public static schoolgriha_criteria3_appraisal2_field1 =
    'G002V001S001C003A002F001';
  public static schoolgriha_criteria3_appraisal2_field1_subfield1 =
    'G002V001S001C003A002F001D001';
  public static schoolgriha_criteria4 = 'G002V001S001C004';
  public static schoolgriha_criteria4_appraisal1 = 'G002V001S001C004A001';
  public static schoolgriha_criteria4_appraisal1_field1 =
    'G002V001S001C004A001F001';
  public static schoolgriha_criteria4_appraisal1_field1_subfield1 =
    'G002V001S001C004A001F001D001';

  public static schoolgriha_criteria5 = 'G002V001S002C005';
  public static schoolgriha_criteria5_appraisal1 = 'G002V001S002C005A001';
  public static schoolgriha_criteria5_appraisal1_field1 =
    'G002V001S002C005A001F001';
  public static schoolgriha_criteria5_appraisal1_field1_subfield1_option2 =
    'G002V001S002C005A001F001O001D002';
  public static schoolgriha_criteria5_appraisal1_field1_subfield1_option3 =
    'G002V001S002C005A001F001O001D003';
  public static schoolgriha_criteria5_appraisal1_field1_subfield1_option4 =
    'G002V001S002C005A001F001O001D004';
  public static schoolgriha_criteria5_appraisal1_field2 =
    'G002V001S002C005A001F002';
  public static schoolgriha_criteria5_appraisal1_field2_subfield1_option2 =
    'G002V001S002C005A001F002O001D002';
  public static schoolgriha_criteria5_appraisal1_field2_subfield1_option3 =
    'G002V001S002C005A001F002O001D003';
  public static schoolgriha_criteria5_appraisal1_field2_subfield1_option4 =
    'G002V001S002C005A001F002O001D004';
  public static schoolgriha_criteria5_appraisal1_field3 =
    'G002V001S002C005A001F003';
  public static schoolgriha_criteria5_appraisal1_field3_subfield1_option2 =
    'G002V001S002C005A001F003O001D002';
  public static schoolgriha_criteria5_appraisal1_field3_subfield1_option3 =
    'G002V001S002C005A001F003O001D003';
  public static schoolgriha_criteria5_appraisal1_field3_subfield1_option4 =
    'G002V001S002C005A001F003O001D004';
  public static schoolgriha_criteria5_appraisal2 = 'G002V001S002C005A002';
  public static schoolgriha_criteria5_appraisal2_field1 =
    'G002V001S002C005A002F001';
  public static schoolgriha_criteria5_appraisal2_field1_subfield1_option2 =
    'G002V001S002C005A002F001O001D002';
  public static schoolgriha_criteria5_appraisal2_field1_subfield1_option3 =
    'G002V001S002C005A002F001O001D003';
  public static schoolgriha_criteria5_appraisal2_field1_subfield1_option4 =
    'G002V001S002C005A002F001O001D004';
  public static schoolgriha_criteria5_appraisal2_field2 =
    'G002V001S002C005A002F002';
  public static schoolgriha_criteria5_appraisal2_field2_subfield1_option2 =
    'G002V001S002C005A002F002O001D002';
  public static schoolgriha_criteria5_appraisal2_field2_subfield1_option3 =
    'G002V001S002C005A002F002O001D003';
  public static schoolgriha_criteria5_appraisal2_field2_subfield1_option4 =
    'G002V001S002C005A002F002O001D004';
  public static schoolgriha_criteria5_appraisal2_field3 =
    'G002V001S002C005A002F003';
  public static schoolgriha_criteria5_appraisal2_field3_subfield1_option2 =
    'G002V001S002C005A002F003O001D002';
  public static schoolgriha_criteria5_appraisal2_field3_subfield1_option3 =
    'G002V001S002C005A002F003O001D003';
  public static schoolgriha_criteria5_appraisal2_field3_subfield1_option4 =
    'G002V001S002C005A002F003O001D004';
  public static schoolgriha_criteria6 = 'G002V001S002C006';
  public static schoolgriha_criteria7 = 'G002V001S002C007';
  public static schoolgriha_criteria7_appraisal1 = 'G002V001S002C007A001';
  public static schoolgriha_criteria7_appraisal1_field2 =
    'G002V001S002C007A001F002';
  public static schoolgriha_criteria7_appraisal1_field1_subfield2_option1 =
    'G002V001S002C007A001F001D002O001';
  public static schoolgriha_criteria7_appraisal1_field1_subfield2_option3 =
    'G002V001S002C007A001F001D002O003';
  public static schoolgriha_criteria7_appraisal1_field1_subfield3_option1 =
    'G002V001S002C007A001F001D003O001';
  public static schoolgriha_criteria7_appraisal1_field1_subfield3_option3 =
    'G002V001S002C007A001F001D003O003';
  public static schoolgriha_criteria7_appraisal1_field2_subfield2_option1 =
    'G002V001S002C007A001F002D002O001';
  public static schoolgriha_criteria7_appraisal1_field2_subfield2_option3 =
    'G002V001S002C007A001F002D002O003';
  public static schoolgriha_criteria7_appraisal1_field2_subfield3_option1 =
    'G002V001S002C007A001F002D003O001';
  public static schoolgriha_criteria7_appraisal1_field2_subfield3_option3 =
    'G002V001S002C007A001F002D003O003';
  public static schoolgriha_criteria7_appraisal2 = 'G002V001S002C007A002';
  public static schoolgriha_criteria7_appraisal2_field2 =
    'G002V001S002C007A002F002';
  public static schoolgriha_criteria7_appraisal2_field1_subfield1_option3 =
    'G002V001S002C007A002F001D001O003';
  public static schoolgriha_criteria7_appraisal2_field1_subfield2_option3 =
    'G002V001S002C007A002F002D001O003';
  public static schoolgriha_criteria8 = 'G002V001S003C008';
  public static schoolgriha_criteria8_appraisal1 = 'G002V001S003C008A001';
  public static schoolgriha_criteria8_appraisal1_field1 =
    'G002V001S003C008A001F001';
  public static schoolgriha_criteria8_appraisal1_field1_subfield1_option1 =
    'G002V001S003C008A001F001D001O001';
  public static schoolgriha_criteria8_appraisal1_field1_subfield2_option2 =
    'G002V001S003C008A001F001D002O002';
  public static schoolgriha_criteria8_appraisal1_field1_subfield2_option5 =
    'G002V001S003C008A001F001D002O005';
  public static schoolgriha_criteria8_appraisal1_field1_subfield3_option1 =
    'G002V001S003C008A001F001D003O001';
  public static schoolgriha_criteria8_appraisal1_field1_subfield4_option2 =
    'G002V001S003C008A001F001D004O002';
  public static schoolgriha_criteria8_appraisal1_field1_subfield4_option5 =
    'G002V001S003C008A001F001D004O005';
  public static schoolgriha_criteria8_appraisal1_field2 =
    'G002V001S003C008A001F002';
  public static schoolgriha_criteria8_appraisal1_field2_subfield1_option1 =
    'G002V001S003C008A001F002D001O001';
  public static schoolgriha_criteria8_appraisal1_field2_subfield2_option1 =
    'G002V001S003C008A001F002D002O001';
  public static schoolgriha_criteria8_appraisal1_field2_subfield2_option2 =
    'G002V001S003C008A001F002D002O002';
  public static schoolgriha_criteria8_appraisal1_field2_subfield2_option3 =
    'G002V001S003C008A001F002D002O003';
  public static schoolgriha_criteria8_appraisal1_field2_subfield2_option4 =
    'G002V001S003C008A001F002D002O004';
  public static schoolgriha_criteria8_appraisal1_field2_subfield2_option5 =
    'G002V001S003C008A001F002D002O005';
  public static schoolgriha_criteria8_appraisal1_field2_subfield3_option1 =
    'G002V001S003C008A001F002D003O001';
  public static schoolgriha_criteria8_appraisal1_field2_subfield4_option1 =
    'G002V001S003C008A001F002D004O001';
  public static schoolgriha_criteria8_appraisal1_field2_subfield4_option2 =
    'G002V001S003C008A001F002D004O002';
  public static schoolgriha_criteria8_appraisal1_field2_subfield4_option3 =
    'G002V001S003C008A001F002D004O003';
  public static schoolgriha_criteria8_appraisal1_field2_subfield4_option4 =
    'G002V001S003C008A001F002D004O004';
  public static schoolgriha_criteria8_appraisal1_field2_subfield4_option5 =
    'G002V001S003C008A001F002D004O005';
  public static schoolgriha_criteria8_appraisal1_field3 =
    'G002V001S003C008A001F003';
  public static schoolgriha_criteria8_appraisal1_field3_subfield1_option1 =
    'G002V001S003C008A001F003D001O001';
  public static schoolgriha_criteria8_appraisal1_field3_subfield2_option2 =
    'G002V001S003C008A001F003D002O002';
  public static schoolgriha_criteria8_appraisal1_field3_subfield2_option3 =
    'G002V001S003C008A001F003D002O003';
  public static schoolgriha_criteria8_appraisal1_field3_subfield2_option3_option2 =
    'G002V001S003C008A001F003D002O003O002';
  public static schoolgriha_criteria8_appraisal1_field3_subfield3_option1 =
    'G002V001S003C008A001F003D003O001';
  public static schoolgriha_criteria8_appraisal1_field3_subfield4_option2 =
    'G002V001S003C008A001F003D004O002';
  public static schoolgriha_criteria8_appraisal1_field3_subfield4_option3 =
    'G002V001S003C008A001F003D004O003';
  public static schoolgriha_criteria8_appraisal1_field3_subfield4_option3_option2 =
    'G002V001S003C008A001F003D004O003O002';
  public static schoolgriha_criteria8_appraisal1_field4 =
    'G002V001S003C008A001F004';
  public static schoolgriha_criteria8_appraisal1_field4_subfield1_option1 =
    'G002V001S003C008A001F004D001O001';
  public static schoolgriha_criteria8_appraisal1_field4_subfield2_option1 =
    'G002V001S003C008A001F004D002O001';
  public static schoolgriha_criteria8_appraisal1_field4_subfield2_option2 =
    'G002V001S003C008A001F004D002O002';
  public static schoolgriha_criteria8_appraisal1_field4_subfield3_option1 =
    'G002V001S003C008A001F004D003O001';
  public static schoolgriha_criteria8_appraisal1_field4_subfield4_option1 =
    'G002V001S003C008A001F004D004O001';
  public static schoolgriha_criteria8_appraisal1_field4_subfield4_option2 =
    'G002V001S003C008A001F004D004O002';
  public static schoolgriha_criteria8_appraisal1_field5 =
    'G002V001S003C008A001F005';
  public static schoolgriha_criteria8_appraisal1_field5_subfield1_option1 =
    'G002V001S003C008A001F005D001O001';
  public static schoolgriha_criteria8_appraisal1_field5_subfield2_option1 =
    'G002V001S003C008A001F005D002O001';
  public static schoolgriha_criteria8_appraisal1_field5_subfield2_option2 =
    'G002V001S003C008A001F005D002O002';
  public static schoolgriha_criteria8_appraisal1_field5_subfield2_option3 =
    'G002V001S003C008A001F005D002O003';
  public static schoolgriha_criteria8_appraisal1_field5_subfield2_option3_option2 =
    'G002V001S003C008A001F005D002O003O002';
  public static schoolgriha_criteria8_appraisal1_field5_subfield3_option1 =
    'G002V001S003C008A001F005D003O001';
  public static schoolgriha_criteria8_appraisal1_field5_subfield4_option1 =
    'G002V001S003C008A001F005D004O001';
  public static schoolgriha_criteria8_appraisal1_field5_subfield4_option2 =
    'G002V001S003C008A001F005D004O002';
  public static schoolgriha_criteria8_appraisal1_field5_subfield4_option3 =
    'G002V001S003C008A001F005D004O003';
  public static schoolgriha_criteria8_appraisal1_field5_subfield4_option3_option2 =
    'G002V001S003C008A001F005D004O003O002';
  public static schoolgriha_criteria9 = 'G002V001S003C009';
  public static schoolgriha_criteria9_appraisal1 = 'G002V001S003C009A001';
  public static schoolgriha_criteria9_appraisal1_field1 =
    'G002V001S003C009A001F001';
  public static schoolgriha_criteria9_appraisal1_field1_option1 =
    'G002V001S003C009A001F001O001';
  public static schoolgriha_criteria9_appraisal1_field1_option2 =
    'G002V001S003C009A001F001O002';
  public static schoolgriha_criteria9_appraisal1_field2 =
    'G002V001S003C009A001F002';
  public static schoolgriha_criteria9_appraisal1_field2_subfield1 =
    'G002V001S003C009A001F002D001';
  public static schoolgriha_criteria9_appraisal1_field2_subfield1_subfield4 =
    'G002V001S003C009A001F002D001D004';
  public static schoolgriha_criteria9_appraisal1_field2_subfield2_subfield3 =
    'G002V001S003C009A001F002D002D003';
  public static schoolgriha_criteria9_appraisal1_field3 =
    'G002V001S003C009A001F003';
  public static schoolgriha_criteria9_appraisal1_field3_subfield1_subfield3 =
    'G002V001S003C009A001F003D001D003';
  public static schoolgriha_criteria10 = 'G002V001S004C010';
  public static schoolgriha_criteria10_appraisal1 = 'G002V001S004C010A001';
  public static schoolgriha_criteria10_appraisal1_field1 =
    'G002V001S004C010A001F001';
  public static schoolgriha_criteria10_appraisal1_field1_option1 =
    'G002V001S004C010A001F001O001';
  public static schoolgriha_criteria10_appraisal1_field1_option2 =
    'G002V001S004C010A001F001O002';
  public static schoolgriha_criteria10_appraisal1_field2 =
    'G002V001S004C010A001F002';
  public static schoolgriha_criteria10_appraisal1_field2_option1 =
    'G002V001S004C010A001F002O001';
  public static schoolgriha_criteria10_appraisal1_field2_option2 =
    'G002V001S004C010A001F002O002';
  public static schoolgriha_criteria10_appraisal1_field3 =
    'G002V001S004C010A001F003';
  public static schoolgriha_criteria10_appraisal1_field4 =
    'G002V001S004C010A001F004';
  public static schoolgriha_criteria10_appraisal1_field5 =
    'G002V001S004C010A001F005';
  public static schoolgriha_criteria10_appraisal1_field5_option3 =
    'G002V001S004C010A001F005O003';
  public static schoolgriha_criteria10_appraisal1_field6 =
    'G002V001S004C010A001F006';
  public static schoolgriha_criteria10_appraisal1_field7 =
    'G002V001S004C010A001F007';
  public static schoolgriha_criteria10_appraisal1_field7_option1 =
    'G002V001S004C010A001F007O001';
  public static schoolgriha_criteria10_appraisal1_field7_option2 =
    'G002V001S004C010A001F007O002';
  public static schoolgriha_criteria10_appraisal1_field8 =
    'G002V001S004C010A001F008';
  public static schoolgriha_criteria10_appraisal1_field8_option3 =
    'G002V001S004C010A001F008O003';
  public static schoolgriha_criteria10_appraisal1_field9 =
    'G002V001S004C010A001F009';
  public static schoolgriha_criteria10_appraisal2 = 'G002V001S004C010A002';
  public static schoolgriha_criteria10_appraisal2_field1 =
    'G002V001S004C010A002F001';
  public static schoolgriha_criteria10_appraisal2_field1_option1 =
    'G002V001S004C010A002F001O001';
  public static schoolgriha_criteria10_appraisal2_field1_option2 =
    'G002V001S004C010A002F001O002';
  public static schoolgriha_criteria10_appraisal2_field2 =
    'G002V001S004C010A002F002';
  public static schoolgriha_criteria10_appraisal2_field2_option1 =
    'G002V001S004C010A002F002O001';
  public static schoolgriha_criteria10_appraisal2_field2_option2 =
    'G002V001S004C010A002F002O002';
  public static schoolgriha_criteria10_appraisal2_field3 =
    'G002V001S004C010A002F003';
  public static schoolgriha_criteria10_appraisal2_field4 =
    'G002V001S004C010A002F004';
  public static schoolgriha_criteria10_appraisal2_field4_option8 =
    'G002V001S004C010A002F004O008';
  public static schoolgriha_criteria10_appraisal2_field5 =
    'G002V001S004C010A002F005';
  public static schoolgriha_criteria10_appraisal2_field5_option8 =
    'G002V001S004C010A002F005O008';
  public static schoolgriha_criteria10_appraisal2_field6 =
    'G002V001S004C010A002F006';
  public static schoolgriha_criteria11 = 'G002V001S004C0011';
  public static schoolgriha_criteria12 = 'G002V001S005C012';
  public static schoolgriha_criteria12_appraisal1 = 'G002V001S005C012A001';
  public static schoolgriha_criteria12_appraisal1_field1_subfield2 =
    'G002V001S005C012A001F001D002';
  public static schoolgriha_criteria12_appraisal1_field1_subfield3 =
    'G002V001S005C012A001F001D003';
  public static schoolgriha_criteria12_appraisal1_field1_subfield3_option3 =
    'G002V001S005C012A001F001D003O003';
  public static schoolgriha_criteria12_appraisal1_field2 =
    'G002V001S005C012A001F002';
  public static schoolgriha_criteria12_appraisal1_field2_subfield2 =
    'G002V001S005C012A001F002D002';
  public static schoolgriha_criteria12_appraisal1_field2_subfield3 =
    'G002V001S005C012A001F002D003';
  public static schoolgriha_criteria12_appraisal1_field2_subfield3_option3 =
    'G002V001S005C012A001F002D003O003';
  public static schoolgriha_criteria12_appraisal2 = 'G002V001S005C012A002';
  public static schoolgriha_criteria12_appraisal2_field2 =
    'G002V001S005C012A002F002';
  public static schoolgriha_criteria12_appraisal3 = 'G002V001S005C012A003';
  public static schoolgriha_criteria12_appraisal3_field2 =
    'G002V001S005C012A003F002';
  public static schoolgriha_criteria14 = 'G002V001S006C0014';
  public static schoolgriha_criteria14_appraisal1 = 'G002V001S006C0014A001';
  public static schoolgriha_criteria14_appraisal1_field1 =
    'G002V001S006C0014A001F001';
  // public static schoolgriha_criteria14_appraisal1_field1_subfield1 = 'G002V001S001C002A001F001D001';
  // public static schoolgriha_criteria14_appraisal1_field2 = 'G002V001S001C002A001F002';
  // public static schoolgriha_criteria14_appraisal1_field2_subfield1 = 'G002V001S001C002A001F002D001';
  // public static schoolgriha_criteria14_appraisal1_field3 = 'G002V001S001C002A001F003';
  // public static schoolgriha_criteria14_appraisal1_field3_subfield1 = 'G002V001S001C002A001F003D001';
  // public static schoolgriha_criteria14_appraisal1_field4 = 'G002V001S001C002A001F004';
  // public static schoolgriha_criteria14_appraisal1_field4_subfield1 = 'G002V001S001C002A001F004D001';
  // public static schoolgriha_criteria14_appraisal1_field5 = 'G002V001S001C002A001F005';
  // public static schoolgriha_criteria14_appraisal1_field5_subfield1 = 'G002V001S001C002A001F005D001';
  // public static schoolgriha_criteria14_appraisal1_field6 = 'G002V001S001C002A001F006';
  // public static schoolgriha_criteria14_appraisal1_field6_subfield1 = 'G002V001S001C002A001F006D001';
  public static schoolgriha_criteria15 = 'G002V001S006C0015';
  public static schoolgriha_criteria15_appraisal1 = 'G002V001S006C0015A001';
  public static schoolgriha_criteria15_appraisal1_field1 =
    'G002V001S006C0015A001F001';
  public static schoolgriha_criteria15_appraisal1_field1_subfield1 =
    'G002V001S006C0015A001F001D001';
  public static schoolgriha_criteria15_appraisal2 = 'G002V001S006C0015A002';
  public static schoolgriha_criteria15_appraisal2_field1 =
    'G002V001S006C0015A002F001';
  public static schoolgriha_criteria15_appraisal2_field1_subfield1 =
    'G002V001S006C0015A002F001D001';
  public static schoolgriha_criteria16 = 'G002V001S007C016';
  public static schoolgriha_criteria16_appraisal1 = 'G002V001S007C016A001';
  public static schoolgriha_criteria16_appraisal1_field3 =
    'G002V001S007C016A001F003';
  public static schoolgriha_criteria16_appraisal1_field3_subfield1_option1 =
    'G002V001S007C016A001F003D001O001';
  public static schoolgriha_criteria16_appraisal1_field3_subfield1_option2 =
    'G002V001S007C016A001F003D001O002';
  public static schoolgriha_criteria16_appraisal1_field3_subfield2 =
    'G002V001S007C016A001F003D002';
  public static schoolgriha_criteria16_appraisal1_field4 =
    'G002V001S007C016A001F004';
  public static schoolgriha_criteria16_appraisal1_field4_subfield1_option1 =
    'G002V001S007C016A001F004D001O001';
  public static schoolgriha_criteria16_appraisal1_field4_subfield1_option2 =
    'G002V001S007C016A001F004D001O002';
  public static schoolgriha_criteria16_appraisal1_field4_subfield2 =
    'G002V001S007C016A001F004D002';
  public static schoolgriha_criteria16_appraisal1_field4_subfield2_option4 =
    'G002V001S007C016A001F004D002D004';
  public static schoolgriha_criteria16_appraisal1_field4_subfield2_option5 =
    'G002V001S007C016A001F004D002D005';
  public static schoolgriha_criteria16_appraisal1_field4_subfield2_option6 =
    'G002V001S007C016A001F004D002D006';
  public static schoolgriha_criteria16_appraisal1_field4_subfield2_option7 =
    'G002V001S007C016A001F004D002D007';
  public static schoolgriha_criteria16_appraisal1_field4_subfield2_option8 =
    'G002V001S007C016A001F004D002D008';
  public static schoolgriha_criteria16_appraisal1_field5 =
    'G002V001S007C016A001F005';
  public static schoolgriha_criteria16_appraisal1_field5_subfield1_option1 =
    'G002V001S007C016A001F005D001O001';
  public static schoolgriha_criteria16_appraisal1_field5_subfield1_option2 =
    'G002V001S007C016A001F005D001O002';
  public static schoolgriha_criteria16_appraisal1_field5_subfield2 =
    'G002V001S007C016A001F005D002';
  public static schoolgriha_criteria16_appraisal1_field5_subfield2_option3 =
    'G002V001S007C016A001F005D002D003';
  public static schoolgriha_criteria16_appraisal1_field5_subfield2_option4 =
    'G002V001S007C016A001F005D002D004';
  public static schoolgriha_criteria16_appraisal1_field5_subfield2_option5 =
    'G002V001S007C016A001F005D002D005';

  //school-griha-criteria-11//

  public static schoolgriha_criteria11_appraisal1 = 'G002V001S004C0011A001';
  public static schoolgriha_criteria11_appraisal2 = 'G002V001S004C0011A002';
  public static schoolgriha_criteria11_appraisal3 = 'G002V001S004C0011A003';
  public static schoolgriha_criteria11_appraisal2_field1 =
    'G002V001S004C0011A002F001';
  public static schoolgriha_criteria11_appraisal2_field2 =
    'G002V001S004C0011A002F002';
  // public static schoolgriha_criteria11_appraisal2_field1_subfield3 = 'G002V001S004C0011A002F003';
  public static schoolgriha_criteria11_appraisal2_field1_subfield4 =
    'G002V001S004C0011A002F004';
  public static schoolgriha_criteria11_appraisal3_field1_subfield2 =
    'G002V001S004C0011A003F002';
  public static schoolgriha_criteria11_appraisal3_field2_subfield1_option4 =
    'G002V001S004C0011A002F002O004';

  public static schoolgriha_criteria11_appraisal2_field1_subfield1_option1 =
    'G002V001S004C0011A002F001D001O001';
  public static schoolgriha_criteria11_appraisal2_field1_subfield1_option2 =
    'G002V001S004C0011A002F001D001O002';
  public static schoolgriha_criteria11_appraisal2_field1_subfield1_option3 =
    'G002V001S004C0011A002F001D001O003';
  public static schoolgriha_criteria11_appraisal2_field1_subfield1_option4 =
    'G002V001S004C0011A002F001D001O004';
  public static schoolgriha_criteria11_appraisal2_field1_subfield2 =
    'G002V001S004C0011A002F001D002';
  public static schoolgriha_criteria11_appraisal2_field1_subfield2_option1 =
    'G002V001S004C0011A002F001D002O001';
  public static schoolgriha_criteria11_appraisal2_field1_subfield3 =
    'G002V001S004C0011A002F001D003';
  public static schoolgriha_criteria11_appraisal2_field2_subfield1_option1 =
    'G002V001S004C0011A002F002D001O001';
  public static schoolgriha_criteria11_appraisal2_field2_subfield1_option2 =
    'G002V001S004C0011A002F002D001O002';
  public static schoolgriha_criteria11_appraisal2_field2_subfield1_option3 =
    'G002V001S004C0011A002F002D001O003';
  public static schoolgriha_criteria11_appraisal2_field2_subfield1_option4 =
    'G002V001S004C0011A002F002D001O004';
  public static schoolgriha_criteria11_appraisal2_field2_subfield2 =
    'G002V001S004C0011A002F002D002';
  public static schoolgriha_criteria11_appraisal2_field2_subfield3 =
    'G002V001S004C0011A002F002D003';
  public static schoolgriha_criteria11_appraisal2_field2_subfield2_option1 =
    'G002V001S004C0011A002F002D002O001';
  public static schoolgriha_criteria11_appraisal1_field1 =
    'G002V001S004C0011A001F001';
  public static schoolgriha_criteria11_appraisal1_field1_subfield1 =
    'G002V001S004C0011A001F001D001';
  public static schoolgriha_criteria11_appraisal1_field2 =
    'G002V001S004C0011A001F002';
  public static schoolgriha_criteria11_appraisal1_field2_subfield1 =
    'G002V001S004C0011A001F002D001';
  public static schoolgriha_criteria11_appraisal1_field3 =
    'G002V001S004C0011A001F003';
  public static schoolgriha_criteria11_appraisal1_field3_subfield1 =
    'G002V001S004C0011A001F003D001';
  public static schoolgriha_criteria11_appraisal1_field4 =
    'G002V001S004C0011A001F004';
  public static schoolgriha_criteria11_appraisal1_field4_subfield1 =
    'G002V001S004C0011A001F004D001';
  public static schoolgriha_criteria11_appraisal1_field5 =
    'G002V001S004C0011A001F005';
  public static schoolgriha_criteria11_appraisal1_field5_subfield1 =
    'G002V001S004C0011A001F005D001';
  public static schoolgriha_criteria11_appraisal1_field6 =
    'G002V001S004C0011A001F006';
  public static schoolgriha_criteria11_appraisal1_field6_subfield1 =
    'G002V001S004C0011A001F006D001';
  public static schoolgriha_criteria11_appraisal3_field1_subfield1 =
    'G002V001S004C0011A003F001D001';
  public static schoolgriha_criteria11_appraisal3_field2_subfield1 =
    'G002V001S004C0011A003F002D001';

  public static schoolgriha_criteria11_appraisal1_field1_subfield2_option1 =
    'G002V001S004C0011A001F001D002O001';
  public static schoolgriha_criteria11_appraisal1_field1_subfield2_option3 =
    'G002V001S004C0011A001F001D002O003';
  public static schoolgriha_criteria11_appraisal1_field1_subfield2_option5 =
    'G002V001S004C0011A001F001D002O005';
  public static schoolgriha_criteria11_appraisal1_field2_subfield2_option1 =
    'G002V001S004C0011A001F002D002O001';
  public static schoolgriha_criteria11_appraisal1_field2_subfield2_option3 =
    'G002V001S004C0011A001F002D002O003';
  public static schoolgriha_criteria11_appraisal1_field2_subfield2_option5 =
    'G002V001S004C0011A001F002D002O005';
  public static fun_activity_field_code = 'G002V001S004C0011A001K001';

  //criteria 13//

  public static schoolgriha_criteria13 = 'G002V001S005C0013';
  public static schoolgriha_criteria13_appraisal1 = 'G002V001S005C0013A001';
  public static schoolgriha_criteria13_appraisal2 = 'G002V001S005C0013A002';
  public static schoolgriha_criteria13_appraisal2_field2 =
    'G002V001S005C0013A002F002';
  public static schoolgriha_criteria13_appraisal2_field2_subfield1 =
    'G002V001S005C0013A002F002D001';
  public static schoolgriha_criteria13_appraisal2_field3_subfield1 =
    'G002V001S005C0013A002F003O003';
  public static schoolgriha_criteria13_appraisal2_field3 =
    'G002V001S005C0013A002F003';
  public static schoolgriha_criteria13_appraisal2_field4 =
    'G002V001S005C0013A002F004';
  public static schoolgriha_criteria13_appraisal2_field2_subfield1_option1 =
    'G002V001S005C0013A002F002D001O001';
  public static schoolgriha_criteria13_appraisal2_field2_subfield1_option5 =
    'G002V001S005C0013A002F002D001O002';
  public static schoolgriha_criteria13_appraisal1_field1 =
    'G002V001S005C0013A001F001';
  public static schoolgriha_criteria13_appraisal1_field2 =
    'G002V001S005C0013A001F002';
  public static schoolgriha_criteria13_appraisal1_field1_option1 =
    'G002V001S005C0013A001F001O001';
  public static schoolgriha_criteria13_appraisal1_field1_option2 =
    'G002V001S005C0013A001F001O002';
  public static schoolgriha_criteria13_appraisal1_compliance1 =
    'G002V001S005C0013P001';
  public static schoolgriha_criteria13_appraisal1_compliance2 =
    'G002V001S005C0013P002';
  public static schoolgriha_criteria13_appraisal2_field3_subfield3 =
    'G002V001S005C0013A002F002D003';
  public static schoolgriha_criteria13_appraisal2_field4_subfield1 =
    'G002V001S005C0013A002F004D001';
  public static schoolgriha_criteria13_appraisal2_field4_subfield2 =
    'G002V001S005C0013A002F004D002';
  public static schoolgriha_criteria13_appraisal2_field4_subfield3 =
    'G002V001S005C0013A002F004D003';
  public static schoolgriha_criteria13_appraisal2_field5 =
    'G002V001S005C0013A002F005';
  public static schoolgriha_criteria13_appraisal2_field5_option3 =
    'G002V001S005C0013A002F005D003';
  public static schoolgriha_criteria13_appraisal2_field5_subfield3 =
    'G002V001S005C0013A002F005D003';

  // Jan griha //
  public static jangriha_criteria1 = 'G003V001S001C001';
  public static jangriha_criteria2 = 'G003V001S001C002';
  public static jangriha_criteria3 = 'G003V001S001C003';
  public static jangriha_criteria4 = 'G003V001S002C004';
  public static jangriha_criteria5 = 'G003V001S002C005';
  public static jangriha_criteria5_appraisal2_field2_subfield2 = 'G003V001S002C005A002F002D002';
  public static jangriha_criteria5_appraisal2_field2_subfield2_option1 = 'G003V001S002C005A002F002D002O001';
  public static jangriha_criteria5_appraisal2_field2_subfield2_option2 = 'G003V001S002C005A002F002D002O002';
  public static jangriha_criteria6 = 'G003V001S002C006';
  public static jangriha_criteria7 = 'G003V001S003C007';
  public static jangriha_criteria8 = 'G003V001S003C008';
  public static jangriha_criteria8_appraisal1_field2 = 'G003V001S003C008A001F002';
  public static jangriha_criteria8_appraisal1_field3 = 'G003V001S003C008A001F003';
  public static jangriha_criteria9 = 'G003V001S004C009';
  public static jangriha_criteria10 = 'G003V001S004C010';
  public static jangriha_criteria11 = 'G003V001S005C011';
  public static jangriha_criteria12 = 'G003V001S006C012';
  public static jangriha_criteria12_appraisal3_field2_subfield_1 = 'G003V001S006C012A003F002D001';
  public static jangriha_criteria12_appraisal3_field2_subfield_2 = 'G003V001S006C012A003F002D002';
  public static jangriha_criteria1_appraisal1 = 'G003V001S001C001A001';
  public static jangriha_criteria1_appraisal2 = 'G003V001S001C001A002';

  // jan griha //
  public static jangriha_floor_plan_fans_predefined_formula_1 = 250*20*0.75;
  public static jangriha_floor_plan_fans_predefined_formula_2 = 1000;
  public static jangriha_floor_plan_geyser_water_per_person = 25;
  public static jangriha_floor_plan_energy_tarif_unit_50 = 4.1;
  public static jangriha_floor_plan_energy_tarif_unit_100 = 5.55;
  public static jangriha_floor_plan_energy_tarif_unit_200 = 7.1;
  public static jangriha_floor_plan_energy_tarif_unit_above = 8.15;
  public static jangriha_floor_plan_water_tarif_unit_8000 = 7;
  public static jangriha_floor_plan_water_tarif_unit_25000 = 11;
  public static jangriha_floor_plan_water_tarif_unit_50000 = 26;
  public static jangriha_floor_plan_water_tarif_unit_above = 45;
  public static jangriha_floor_plan_water_month_cost_design_case = 11.51;
  public static jangriha_floor_plan_water_month_cost_base_case = 123.6;

  public static formulaRoundUp = 'roundup';
  public static formulaDecimal2 = 'decimal';
  public static conditionApplicable = 'applicable';
  public static PAVEDAREA = 'PAVEDAREA';
  public static GROUNDCOVERAGE = 'GROUNDCOVERAGE';
  public static MOREVALUE = 'MOREVALUE';
  public static siteAreaString = ' of the Built Up Area';
  public static bultupAreaString = ' of the Site Area';

  public static typeResidential = 'Residential';
  public static typeNonResidential = 'Non Residential';
  public static typeBungalow = 'Bungalow';
  public static typeVillas = 'Villas/Mansions';
  public static typeMultiDwellingUnits = 'Multi-dwelling units';
  public static typeHostels = 'Hostels';
  public static typeMilitaryBarracks = 'Military Barracks';

  public static amenityResidential = ' Minimum 7 amenities must be selected.';
  public static amenityNonResidentail =
    ' Minimum 9 amenities must be selected.';

  public static typeRadio = 'radio';
  public static typeDate = 'date';
  public static typeTextArea = 'textarea';
  public static typeInputBox = 'input-box';
  public static typeCheckBox = 'check-box';
  public static typeDropdown = 'dropdown';
  public static typeCondition = 'CONDITION';
  public static typeValue = 'VALUE';
  public static typeDistance = 'DISTANCE';
  public static typeAmenity = 'AMENITY';
  public static typeJson = 'json';
  public static typeTable = 'table';
  public static typeText = 'text';
  public static typeNumber = 'number';
  public static typeTime = 'time';

  public static validatorRound = 'round';

  public static optionNoStrategy = 'No strategy adopted';
  public static optionNone = 'none of the above';

  public static internal = 'internal';
  public static external = 'external';
  public static plaster = 'plaster';
  public static falseCeiling = 'falseCeiling';
  public static flooring = 'flooring';
  public static doorPanels = 'doorPanels';
  public static windowFrames = 'windowFrames';
  public static windowDoor = 'doorFrames';

  public static svagriha_criteria3ShadingNoShading = 'No Shading';
  public static svagriha_criteria3ShadingOverhang = 'OverHang';
  public static svagriha_criteria3ShadingVerticalLeft = 'Vertical fin on left';
  public static svagriha_criteria3ShadingVerticalRight =
    'Vertical fin on right';
  public static svagriha_criteria3ShadingVerticalBoth =
    'Vertical fins on both sides';
  public static svagriha_criteria3ShadingOverhangVerticalLeft =
    'Overhang and vertical fin on left';
  public static svagriha_criteria3ShadingOverhangVerticalRight =
    'Overhang and vertical fin on right';
  public static svagriha_criteria3ShadingOverhangVerticalBoth =
    'Overhang and vertical fin on both sides';

  public static zero = 0;

  static PROJECT_STATUS_REGISTERED = 'REGISTERED';
  static PROJECT_STATUS_REJECTED = 'REJECTED';
  static PROJECT_STATUS_INITIATED = 'INITIATED';
  static PROJECT_STATUS_INPROGRESS = 'IN PROGRESS';
  static PROJECT_PENDING_SUBMISSION = 'PENDING_SUBMISSION';
  static PROJECT_PENDING_APPROVAL = 'PENDING_APPROVAL';
  static PROJECT_REWORK_REQUIRED = 'REWORK_REQUIRED';
  static PROJECT_STATUS_SUBMITTED = 'SUBMITTED';
  static PROJECT_SUBMIT_TO_EVALUATOR = 'SUBMIT_TO_EVALUATOR';
  static PROJECT_STATUS_ROUND_1_AUDIT_1 = 'ROUND_1_AUDIT_1';
  static PROJECT_STATUS_ROUND_1_AUDIT_2 = 'ROUND_1_AUDIT_2';
  static PROJECT_STATUS_ROUND_2_AUDIT_1 = 'ROUND_2_AUDIT_1';
  static PROJECT_STATUS_ROUND_2_AUDIT_2 = 'ROUND_2_AUDIT_2';
  static PROJECT_STATUS_EVALUATED = 'EVALUATED';
  static PROJECT_STATUS_CERTIFIED = 'CERTIFIED';
  static PROJECT_STATUS_DELETED = 'DELETED';
  static PROJECT_PROCESSING_COORDINATOR = 'PROCESSING_COORDINATOR';
  static PROJECT_PROCESSING_EVALUATOR = 'PROCESSING_EVALUATOR';
  static PROJECT_REJECTED = 'REJECTED';
  static PROJECT_APPROVED = 'APPROVED';
  static PROJECT_CLOSED = 'CLOSED';
  static PROJECT_COMPLETED = 'COMPLETED';

  static CRITERIA_PENDING_SUBMISSION = 'PENDING_SUBMISSION';
  static CRITERIA_SUBMITTED = 'SUBMITTED';
  static CRITERIA_PENDING_APPROVAL = 'PENDING_APPROVAL';
  static CRITERIA_REWORK_REQUIRED = 'REWORK_REQUIRED';
  static CRITERIA_L1_APPROVED = 'L1_APPROVED';
  static CRITERIA_L1_REJECTED = 'L1_REJECTED';
  static CRITERA_PENDING_L2_APPROVAL = 'PENDING_L2_APPROVAL';
  static CRITERIA_L2_APPROVED = 'L2_APPROVED';
  static CRITERIA_L2_REJECTED = 'L2_REJECTED';

  static APPRAISAL_PENDING = 'PENDING';
  static APPRAISAL_COMPLETED = 'COMPLETED';

  static SUBSCRIBE_URL = '/usernotification/subscribe';

  static CERTIFICATION_STATUS_PENDING = 'PENDING';
  static CERTIFICATION_STATUS_CERTIFIED = 'CERTIFIED';
  static CERTIFICATION_STATUS_DENY = 'DENY';

  static SITEVISIT_PENDING = 'PENDING';
  static SITEVISIT_DONE = 'DONE';

  // purpose variable//

  static QC = 'QC';
  static SITE_VISIT_COORDINATOR = 'SITE_VISIT_COORDINATOR';
  static SITE_VISIT_COORDINATOR2 = 'SITE_VISIT_COORDINATOR2';
  static WORKSHOP_COORDINATOR = 'WORKSHOP_COORDINATOR';
  static WORKSHOP_COORDINATOR2 = 'WORKSHOP_COORDINATOR2';
  static PROJECT_COORDINATOR = 'PROJECT_COORDINATOR';
  static PROJECT_CLIENT = 'PROJECT_CLIENT';
  static EVALUATOR = 'EVALUATOR';
  static Consultant = 'Consultant';

  public static USER_ADMIN = "Admin";
  public static USER_SUPER_ADMIN = 'Super-Admin';
  public static USER_COORDINATOR = 'Coordinator';
  public static USER_CLIENT = 'Client';
}
