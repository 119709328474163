import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { ScoreCardComponent } from '../../score-card/score-card.component';
import { SectionSummaryService } from 'src/app/shared/services/section-summary/section-summary.service';
import { variableConstantFile } from 'src/assets/config/variableConstantFile';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SubProjectService } from 'src/app/shared/services/sub-project/sub-project.service';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';

@Component({
  selector: 'app-approval-project',
  templateUrl: './approval-project.component.html',
  styleUrl: './approval-project.component.scss'
})
export class ApprovalProjectComponent {
  safePdfUrl: SafeResourceUrl;
  zoom: number = 1;
  subProjectDetails: any;
  projectDetails: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: { pdfUrl: string },private sanitizer: DomSanitizer,
  private sectionSummaryService: SectionSummaryService,
  private snackbars: MatSnackBar,
  private dataSharing: DataSharingService,
  private subProjectService: SubProjectService,private dialog:MatDialogRef<ScoreCardComponent>) {
    this.dataSharing.subProjectDetails$.subscribe((data) => {
      this.subProjectDetails = data;
      if (this.subProjectDetails) {
        console.log(this.subProjectDetails);
      }
    });
    this.dataSharing.projectDetails$.subscribe((data) => {
      this.projectDetails = data;
    });
    this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.pdfUrl);
  }

  onClose(): void {
    // Logic to close the dialog
    this.dialog.close({result:false});
  }

  onSubmit():void {
    // this.dialog.close({result:true});
    const result = confirm('Do you want to submit the project?');

    if (result) {
      const body = {
        submission_status: variableConstantFile.PROJECT_STATUS_SUBMITTED,
        // status: variableConstantFile.PROJECT_PENDING_APPROVAL,
        pending_at: 'Coordinator',
      };
      this.sectionSummaryService
        .updateProjectStatus(this.subProjectDetails.project_code, body)
        .subscribe({
          next: (res) => {
            this.snackbars.open('Project is Submitted to Coordinator', 'Ok', {
              panelClass: ['snackbar-success'],
              duration: 3000,
            });
            this.getProject();
          },
          error: (err) => {
            this.snackbars.open('Something went wrong!', 'Ok', {
              panelClass: ['snackbar-error'],
              duration: 3000,
            });
            console.error(err);
          },
        });
    }
  }

  getProject() {
    if (this.subProjectDetails.project_code) {
      this.subProjectService
        .getSingleSubProject(
          `${this.projectDetails.project_code}?project_code=${this.subProjectDetails.project_code}`
        )
        .subscribe((projectlistdata: any) => {
          const projectListData = projectlistdata.data[0];
          this.dataSharing.updateSubProjectDetailsData(projectListData);
          this.dialog.close();
        });
    }
  }
}
