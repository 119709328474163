<mat-nav-list class="mat-nav">
  <div *ngFor="let menuItem of menus" class="list-of-item" [class.active]="getLastPathSegment() === menuItem.code" (click)="navigateTo(menuItem.link)">
    <span *ngIf="!menuItem.children || menuItem.children.length === 0">
      <mat-list-item class="custom-list">
        <mat-icon *ngIf="menuItem.hasIcon">{{ menuItem.icon }}</mat-icon>
        <!-- <img [src]="menuItem.icon" alt=""> -->
        <div>{{ menuItem.name }}</div>
      </mat-list-item>
    </span>
  </div>
</mat-nav-list>