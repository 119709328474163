import { dev_config } from "src/assets/config/dev_config";

export const environment = {
    apiDomain: dev_config.apiDomain,
    version: dev_config.version,
    app: dev_config.app,
    audience: dev_config.audience,
    svagriha_url:dev_config.svagriha_url,
    griha_url:dev_config.griha_url,
    old_griha_base: dev_config.old_griha_base,
    authDomain:dev_config.authDomain,
    authClientId:dev_config.clientId,
    backRedirection: {
        admin: dev_config.backRedirection.admin,
        client: dev_config.backRedirection.client,
        coordinator: dev_config.backRedirection.coordinator,
    },
    pushNotiPublicKey:dev_config.pushNotiPublicKey,
    myfreshworks:dev_config.myfreshworks,
    production:true,
    googleMapKey:dev_config.googleMapKey,
    env:'dev'
};
