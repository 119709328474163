<h2 mat-dialog-title class="border-bottom">Preview</h2>
<mat-dialog-content style="padding: 0;">
    <pdf-viewer [src]="data.pdfUrl" [render-text]="true" [original-size]="false"
        style="width: 100%; height: 100%;" [zoom]="zoom"></pdf-viewer>
</mat-dialog-content>
<mat-dialog-actions class="border-top">
    <mat-icon (click)="zoom = zoom + 0.1">zoom_in</mat-icon>
    <mat-icon (click)="zoom>0.3?zoom = zoom - 0.1:1">zoom_out</mat-icon>
    <button class="close-btn" (click)="onClose()">Close</button>
    <button class="submit-btn" (click)="onSubmit()">Submit</button>
</mat-dialog-actions>