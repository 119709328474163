import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { DataSharingService } from '../services/data-sharing.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  @Input() menus: any[];
  @Input() project_code: any;
  segmentLength: number;
  lastPathSegment: string;
  URL = environment.griha_url;
  clientName = '';
  clientEmail = '';
  myfreshworks = environment.myfreshworks;

  constructor(
    public auth: AuthService,
    private dataSharingService: DataSharingService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.menus.forEach((ele: string) => {
      console.log('url ', ele.link);
    });
  }
  route(e: any) {
    console.log(e);
  }

  navigateTo(route: string) {
    if (route === 'logout') {
      this.logOut();
    } else if (route === 'myfreshworks') {
      // this.router.navigateByUrl(this.myfreshworks);
      window.location.href = this.myfreshworks;
    } else if (route === 'oldpanel'){
      if(this.dataSharingService.clientRole == 'Admin' || this.dataSharingService.clientRole == 'Super-Admin'){
        window.location.href = environment.old_griha_base + '/Admin/Index'
        // window.location.href = environment.griha_url
        // console.log('true',dataService.clientRole)
      }else{
        // console.log('false', dataService.clientRole)
        window.location.href = environment.old_griha_base + '/Project/ProjectList'
        // window.location.href = environment.griha_url
        
      }
    } else {
      const url = route.replace('#', this.project_code);
      this.router.navigateByUrl(url);
    }
  }

  getLastPathSegment() {
    const url = this.router.url;
    const urlSegments = url.split('/');
    this.segmentLength = urlSegments.length;
    // console.log(this.segmentLength);
    this.lastPathSegment = urlSegments[urlSegments.length - 1];
    // console.log(this.lastPathSegment); // This will log the last path segment
    return this.lastPathSegment;
  }

  logOut() {
    this.auth.logout({ logoutParams: { returnTo: this.URL } });
    console.log(this.URL);
    // window.location.href = url;
  }

  /*onMenuExpand(targetMenu: any): void {
    this.menus.forEach(element => {
      element.open = false;
    });
    const idx = this.menus.findIndex(item => item.name === targetMenu.menuName);
    this.menus[idx].open = targetMenu.isOpen;
    this.menus = JSON.parse(JSON.stringify(this.menus));
  }*/
}
