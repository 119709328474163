
<!-- <div *ngIf="auth.isAuthenticated$ | async"> -->

<div *ngIf="isAuthenticated" class="main-app" #mainApp>
  <router-outlet></router-outlet>
</div>

<div class="loader-overlay" *ngIf="loaderService.isLoading | async">
  <div class="loader">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
