import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { FourzerofourComponent } from './fourzerofour/fourzerofour.component';
import { OldPanelComponent } from './admin/old-panel/old-panel.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';

const routes: Routes = [
  {
    path: 'forbidden',
    component: ForbiddenComponent,
  },
  {
    path: 'admin',
    data: { module: 'admin' },
    loadChildren: () =>
      import(`./admin/admin.module`).then((m) => m.AdminModule),
  },
  {
    path: 'client',
    data: { module: 'jan-griha-client' },
    loadChildren: () =>
      import('./jan-griha-client/jan-griha-client.module').then(
        (m) => m.JanGrihaClientModule
      ),
  },
  {
    path: 'coordinator',
    data: { module: 'coordinator' },
    loadChildren: () =>
      import(`./jan-griha-coordinator/jan-griha-coordinator.module`).then(
        (m) => m.JanGrihaCoordinatorModule
      ),
  },
  { path: 'fourzerofour', component: FourzerofourComponent },
  { path: 'oldpanel', component: OldPanelComponent },
  { path: '**', redirectTo: '/fourzerofour', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      enableTracing: false,
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
