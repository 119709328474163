import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DataSharingService } from '../data-sharing.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SectionSummaryService {
  
  private CRITERIA_URL='/criteria/#';
  private SUMMARY_UI_URL=`/app/#/summaryui/`
  private UPDATE_PROJECT_URL='/project/#'
  private UPDATE_CRITERIA_URL='/criteria/#'
  private SECTION_URL='/projectsection?project=#'

  constructor(private httpClient:HttpClient) {
   }


  public getCriteriaData(id:any){
    return this.httpClient.get(this.CRITERIA_URL.replace('#',id));
  }

  public getSectionData(id:any){
    return this.httpClient.get(this.SECTION_URL.replace('#',id));
  }

  public getSummaryUI(app_id:string){
    return this.httpClient.get(this.SUMMARY_UI_URL.replace('#',app_id));
  }

 public updateProjectStatus(project_code:string,body:any){
  return this.httpClient.patch(this.UPDATE_PROJECT_URL.replace('#',project_code),body);
 }

 public updateCriteriaStatus(project_id:string,body:any){
  return this.httpClient.patch(this.UPDATE_CRITERIA_URL.replace('#',project_id),body);
 }
}
