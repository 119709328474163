<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource">
    <!-- Project Details Column -->
    <ng-container matColumnDef="project">
      <th mat-header-cell *matHeaderCellDef>Project Details</th>
      <td mat-cell *matCellDef="let element" class="username-cell">
        <div>
          {{ element.name }}
        </div>
        <!-- <div>{{element?.project?.org}}</div>
        <div>Version: <strong>{{element?.project?.version}}</strong></div> -->
      </td>
    </ng-container>

    <!-- Project Code Column -->
    <ng-container matColumnDef="project_code">
      <th mat-header-cell *matHeaderCellDef>Project Code</th>
      <td mat-cell *matCellDef="let element" class="username-cell">
        <div>
          {{ element.project_code }}
        </div>
        <div>({{element?.app?.name}})</div>
        <!-- <div>Version: <strong>{{element?.project?.version}}</strong></div> -->
      </td>
    </ng-container>

    <!-- Client Deatils Column -->
    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef>Client Details</th>
      <td mat-cell *matCellDef="let element" class="username-cell">
        <div>{{ element.client.name }}</div>
        <div>{{ element.client.state }}</div>
      </td>
    </ng-container>

    <!-- Registered Date Column -->
    <ng-container matColumnDef="regDate">
      <th mat-header-cell *matHeaderCellDef>Registered Date</th>
      <td mat-cell *matCellDef="let element" class="username-cell">
        <div>{{ element.created_at | dateFormat }}</div>
      </td>
    </ng-container>

    <!-- Proj Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element"  class="username-cell">
        <div class="project-name">{{ element.status }}</div>
        <!-- <div class="project-desc-title">
          Payment : <span class="project-desc" *ngIf="element.payment_status?.status; else noRecord">{{ element.payment_status.status }}</span>
        </div> -->
        <div class="project-desc-title">
          Optional Site Visit : <span class="project-desc" *ngIf="element.site_visit_optional_status; else noRecord">{{ element.site_visit_optional_status }}</span>
        </div>
        <div class="project-desc-title">
          Workshop : <span class="project-desc" *ngIf="element.workshop_status; else noRecord">{{ element.workshop_status }}</span>
        </div>
        <div class="project-desc-title">
          Final Site Visit : <span class="project-desc" *ngIf="element.site_visit_mandatory_status; else noRecord">{{ element.site_visit_mandatory_status }}</span>
        </div>
      </td>
    </ng-container>

    <!-- Proj Type Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let element"  class="username-cell">
        <div>{{ element.type }}</div>
      </td>
    </ng-container>

    <!-- Proj Coordinator Column -->
    <ng-container matColumnDef="coordinator">
      <th mat-header-cell *matHeaderCellDef>Coordinator</th>
      <td mat-cell *matCellDef="let element" class="username-cell">
        <div *ngIf="element.coordinator?.name; else noRecord">
          {{ element.coordinator.name }}
        </div>
      </td>
    </ng-container>

    <!-- Proj Coordinator Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
        <app-action-column [rowData]="element" [canView]="canView" [canEdit]="canEdit" [canDelete]="canDelete"
          [canAssignCoordinator]="canAssignCoordinator" [canInitiateProject]="canInitiateProject"
          [canViewLogs]="canViewLogs" (delete)="onDelete($event)" (edit)="onEdit($event)" (view)="onView($event)" [canViewSettings]="canViewSettings" (viewSettings)="onViewSettings($event)"
          (initiate)="onInitiate($event)" (assignUser)="onAssignUser($event)">
        </app-action-column>
      </td>
    </ng-container>
    <ng-template #noRecord>
      <span class="no-record">NA</span>
    </ng-template>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of Projects" [length]="totalItems" [pageSize]="pageSize" [pageIndex]="pageIndex" (page)="onPageChange($event)">
  </mat-paginator>
</div>