<div class="griha-project-detail-header__wrapper">
  <div class="project-detail-notification">
    <div
      *ngIf="IsEvaluatorsNotAssigned && userRole==='Coordinator' && projectDetails?.submission_status!==variableConstantFile.PROJECT_PENDING_SUBMISSION && (((projectDetails?.coordinator_target_date | dateToRemainingDays)-25)>0)"
      class="error-status">Assign Evaluator to the project. Delayed by {{(projectDetails?.coordinator_target_date |
      dateToRemainingDays)-25}} days.</div>
  </div>
  <div class="griha-project-detail-header__wrapper__icons">
    <div class="icons">
      <mat-form-field appearance="outline" class="griha-project-detail-header__wrapper__icons--details">
        <mat-select [(ngModel)]="selectedValue" (selectionChange)="onSelectionChange($event.value)">
          <mat-option value="projectDetail">Project Detail</mat-option>
          <mat-option value="personalDetail">Personal Detail</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="icons">
        <button class="project-detail-header--btn_workshop" *ngIf="userRole!=='Evaluator'"
          (click)="onWorkshopSchedule('siteVisit')">
          <div class="project-detail-header--btn_workshop__section">
            <!-- <mat-icon>calendar_month</mat-icon> -->
            <img src="/assets/images/calendar.svg" />
            <span class="project-detail-header--btn_workshop__section---text">Optional Site Visit</span>
          </div>
        </button>
        <button class="project-detail-header--btn_workshop" *ngIf="userRole!=='Evaluator'"
          (click)="onWorkshopSchedule('workshop')">
          <div class="project-detail-header--btn_workshop__section">
            <img src="/assets/images/calendar.svg" />
            <span class="project-detail-header--btn_workshop__section---text"> Optional Workshop</span>
          </div>
        </button>
        <!-- Need to added this after testing done-->
        <!-- [disabled]="projectDetails?.submission_status===variableConstantFile.PROJECT_PENDING_SUBMISSION" 
          [ngClass]="projectDetails?.submission_status===variableConstantFile.PROJECT_PENDING_SUBMISSION?'project-detail-header--btn_workshop project-detail-header--btn_workshop_disabled':'project-detail-header--btn_workshop'" -->
        <button class="project-detail-header--btn_workshop"
          [disabled]="projectDetails?.submission_status===variableConstantFile.PROJECT_PENDING_SUBMISSION"
          [matTooltip]="projectDetails?.submission_status===variableConstantFile.PROJECT_PENDING_SUBMISSION?'Mandatory Sitevisit can be scheduled once project submitted':''"
          [ngClass]="projectDetails?.submission_status===variableConstantFile.PROJECT_PENDING_SUBMISSION?'project-detail-header--btn_workshop project-detail-header--btn_workshop_disabled':'project-detail-header--btn_workshop'"
          *ngIf="userRole!=='Evaluator'" (click)="onWorkshopSchedule('mandatory_siteVisit')">
          <div class="project-detail-header--btn_workshop__section">
            <img *ngIf="projectDetails?.submission_status!==variableConstantFile.PROJECT_PENDING_SUBMISSION"
              src="/assets/images/calendar.svg" />
            <img *ngIf="projectDetails?.submission_status===variableConstantFile.PROJECT_PENDING_SUBMISSION"
              src="/assets/images/calendar_grey.svg" />
            <span class="project-detail-header--btn_workshop__section---text">Final Site Visit</span>
          </div>
        </button>
      </div>
    </div>
    <!-- <button (click)="assignUser()" class="griha-project-detail-header__wrapper__icons--assign-user">
      <img src="/assets/images/person_add.svg" />
    </button> -->
    <div class="project-status-container"
      *ngIf="(userRole==='Evaluator' || userRole==='Coordinator') && (projectDetails?.submission_status!==variableConstantFile.PROJECT_STATUS_EVALUATED && projectDetails?.submission_status!==variableConstantFile.PROJECT_STATUS_CERTIFIED)">
      <div class="project-status-items" *ngIf="userRole==='Coordinator'">
        <div class="project-status-items-title"><img src="assets/images/alarm_success.svg"><span
            class="project-status-success">Overall project status : </span></div>
        <div class="project-status-details"
          *ngIf="projectDetails?.submission_status!==variableConstantFile.PROJECT_PENDING_SUBMISSION">
          <span
            *ngIf="(projectDetails?.coordinator_target_date | dateToRemainingDays)>=0">{{projectDetails?.coordinator_target_date
            | dateToRemainingDays | absoluteValue}} days left</span>
          <span
            *ngIf="(projectDetails?.coordinator_target_date | dateToRemainingDays)<0">{{projectDetails?.coordinator_target_date
            | dateToRemainingDays | absoluteValue}} days delay</span>
        </div>
        <div class="project-status-details"
          *ngIf="projectDetails?.submission_status===variableConstantFile.PROJECT_PENDING_SUBMISSION">
          Submission pending
        </div>
      </div>
      <div class="project-status-items">
        <div class="project-status-items-title"><img src="assets/images/alarm_error.svg"><span
            class="project-status-blue">Evaluation status : </span></div>
        <div class="project-status-details"
          *ngIf="(projectDetails?.submission_status===variableConstantFile.PROJECT_SUBMIT_TO_EVALUATOR)">
          <span *ngIf="IsEvaluatorsNotAssigned">Not assigned yet</span>
          <span
            *ngIf="(projectDetails?.evaluator_target_date | dateToRemainingDays)>=0 && !IsEvaluatorsNotAssigned">{{projectDetails?.evaluator_target_date
            | dateToRemainingDays | absoluteValue}} days left</span>
          <span
            *ngIf="(projectDetails?.evaluator_target_date | dateToRemainingDays)<0 && !IsEvaluatorsNotAssigned">{{projectDetails?.evaluator_target_date
            | dateToRemainingDays | absoluteValue}} days delay</span>
          <img (click)="viewRequest()" src="assets/images/history.svg" alt=""
            *ngIf="!IsEvaluatorsNotAssigned && (projectDetails?.submission_status===variableConstantFile.PROJECT_SUBMIT_TO_EVALUATOR)">
        </div>
        <div class="project-status-details"
          *ngIf="projectDetails?.submission_status!==variableConstantFile.PROJECT_SUBMIT_TO_EVALUATOR">
          Submission pending
        </div>
      </div>
    </div>
    <div class="project-status-container"
      *ngIf="(userRole==='Evaluator' || userRole==='Coordinator') && (projectDetails?.submission_status===variableConstantFile.PROJECT_STATUS_EVALUATED || projectDetails?.submission_status===variableConstantFile.PROJECT_STATUS_CERTIFIED)">
      Project Approved
    </div>
  </div>

</div>