import { Component, Input, Output, EventEmitter, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AssignCoordinatorComponent } from '../../admin/project/project-detail-header/assign-coordinator/assign-coordinator.component';
import { ViewActivityLogsComponent } from '../../admin/project/project-detail-header/view-activity-logs/view-activity-logs.component';
import { DataSharingService } from '../services/data-sharing.service';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'app-action-column',
  templateUrl: './action-column.component.html',
  styleUrls: ['./action-column.component.scss']
})
export class ActionColumnComponent {
  constructor(public matDialog: MatDialog,private dataSharingService:DataSharingService) { }
  @Input() canView: boolean;
  @Input() canEdit: boolean;
  @Input() canDelete: boolean;
  @Input() canInitiateProject: boolean;
  @Input() canAssignCoordinator: boolean;
  @Input() canViewLogs: boolean;
  @Input() canViewSettings:boolean;
  @Input() rowData: any;
  isLogsOpened:boolean = false;
  @Output() view = new EventEmitter<any>();
  @Output() edit = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() initiate = new EventEmitter<any>();
  @Output() assignUser = new EventEmitter<any>();
  @Output() viewSettings = new EventEmitter<any>();
  @Output() viewActivity = new EventEmitter<any>();
  userRole:string;
  @ViewChild('drawer') drawer: MatDrawer;

 ngOnInit(){
  this.userRole = this.dataSharingService.clientRole;
 }

  onViewAction = () => {
    this.view.emit(this.rowData);
  };

  onDeleteAction = () => {
    this.delete.emit(this.rowData)
  }

  onEditAction = () => {
    this.edit.emit(this.rowData)
  }
  assignCoordinator = () => {
    this.assignUser.emit(this.rowData)
  }

  viewLogs() {
    this.isLogsOpened = true;
    this.viewActivity.emit(this.rowData)
    this.drawer.open();
  }

  closeLogs(){
    this.drawer.toggle();
    this.isLogsOpened = false;
  }

  initiateProject = () => {
    this.initiate.emit(this.rowData)
  }

  onViewSettings=()=>{
    this.viewSettings.emit(this.rowData);
  }

}
