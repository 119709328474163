import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'dateFormatNumber'
})
export class DateFormatNumberPipe implements PipeTransform {

  transform(value: string): string {
    // Assuming value is in the format "2023-10-06T04:24:48.500Z"
    if(value){
      const formattedDate = moment(value).format('DD-MM-YYYY');
      console.log(formattedDate);
      return formattedDate;
    }else{
      return ''
    }
  }

}
