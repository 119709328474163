<div class="workshop-container">

  <!-- header -->
  <div class="blank_workshop_wrapper__header">
    <label class="blank_workshop_wrapper__header--title">{{getTitle()}}</label>
  </div>


  <div class="workshop-container-scheduler--wrapper">
    <div class="workshop-container-scheduler">
      <div *ngIf="canScheduleWorkshop" class="workshop-container-scheduler-checkbox">
        <div class="checkbox-container">
          <input type="checkbox" class="custom-checkbox" [(ngModel)]="isCheckedWorkshop"
            (change)="onCheckedWorkshop($event)">
          <span [ngClass]="isCheckedWorkshop?'font-bold':''">Workshop</span>
        </div>
        <div class="checkbox-container">
          <input type="checkbox" class="custom-checkbox" *ngIf="!hasSiteVisit" [(ngModel)]="isCheckedSiteVisit"
            (change)="onCheckedSiteVisit($event)">
          <span [ngClass]="isCheckedSiteVisit?'font-bold':''">SiteVisit</span>
        </div>
      </div>
      <!-- calendar view -->

      <app-griha-calendar [selectedDateValue]="selectedDateValue" (onSelectedDate)="onSelectedWeek($event)">
      </app-griha-calendar>

      <div class="workshop-container-scheduler__clientside-representative-email-container">
        <label class="workshop-container-scheduler__clientside-representative-email-container-text">Client Side
          Representative Email</label>
        <input class="workshop-container-scheduler__clientside-representative-email-container--input" matInput
          type="email" [(ngModel)]="representativeEmail" placeholder="Enter email" (keydown)="onKeyPress($event)">
        <mat-error *ngIf="representativeEmailValidations">
          First Name is Required!
        </mat-error>
      </div>

      <div class="workshop-container-scheduler__clientside-representative-number-container">
        <label class="workshop-container-scheduler__clientside-representative-number-container-text">Client Side
          Representative Number</label>
        <input matInput type="text" [(ngModel)]="representativeNumber" placeholder="Enter mobile number"
          class="workshop-container-scheduler__clientside-representative-number-container--input"
          (keydown)="onKeyPress($event)">
        <mat-error *ngIf="representativeNumberValidations">
          First Name is Required!
        </mat-error>
      </div>
      <!-- add attendees view -->
      <div class="workshop-container-scheduler__add-attendee-container" *ngIf="isWorkshopOrBothChecked">
        <label class="workshop-container-scheduler__add-attendee-container-text">Add Attendees</label>
        <input matInput type="email" [(ngModel)]="attendeesName" placeholder="Enter email"
          class="workshop-container-scheduler__add-attendee-container--input" (keydown)="onKeyPress($event)"
          (blur)="enterAttendee()">
        <app-meeting-attendees [attendees]="attendeesNames" [isRemoval]="removal"
          (onDeletedAttendees)="onDeletedAttendees($event)"></app-meeting-attendees>
      </div>

    </div>

    <div class="workshop-container__bottom">
      <button class="btn btn-text" (click)="onCancelSchedule()">Cancel</button>
      <button class="btn btn-primary" [disabled]="isAttendeeInvalid" (click)="onClickSchedule()">{{action}}</button>
    </div>
  </div>


</div>