import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ResponsePacket } from '../../../model/ResponsePacket';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { UploadDocModel } from '../../../model/upload_doc.model';
import { ProjectData } from '../../../model/ProjectData';

export interface UploadUrl {
  upload_url: string;
}

export interface ProjectUrl {
  project_code: string;
  upload_url: string;
}
export interface DownloadFile {
  project_code: string;
  get_url: Urls[];
}

export interface Urls {
  name: string;
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private UPLOAD_CHECKLIST_DOC = '/project/checklistdocs/#?filename={}';
  private UPLOAD_ATTENDEES_LIST = '/project/attendees/#?filename={}';
  private GET_CHECKLIST_REQUEST = '/project/checklistdocs/{}';
  private GET_ATTENDEES_REQUEST = '/project/attendees/{}';
  private HOLIDAY_UPLOAD_SERVICE = '/app/importholiday';
  private HOLIDAY_LIST = '/app/holidays';
  private GET_CRITERIA_DATA = '/criteria/#';
  private DOWNLOAD_CERTIFICATE_URL = '/project/certificate/#';
  // private INVOICE_DOWNLOAD = 'http://124.123.18.67:55443/grihaintegration/api/SVAGriha2/GetInvoice'
  private INVOICE = '/project/invoice/#';
  private PROJECT_BY_ID_URL = '/project?project_code=#';
  private GET_STATE_URL = '/state';
  private GET_CITY_URL = '/app/city';
  private PROJECT_URL = '/project';
  private PROJECT_SUMMARY_URL = '/project/exportProjectSummary/#';
  private PROJECT_URL_ID = '/project/#';
  private PROJECT_LOGO_URL = '/project/exportClientLogo/#';
  private CRITERIA_CONFIG_URL = '/app/criteriaconfig';
  private deleteSubProjects = '/project/deleteSubProjects';

  constructor(private http: HttpClient) {}

  public invoiceDownload(projectCode: string) {
    return this.http.get(this.INVOICE.replace('#', projectCode));
  }

  public deleteSubProject(projectCode: any, confirm: string) {
    const url = `${this.deleteSubProjects}/${projectCode}?confirm=${confirm}`;
    return this.http.delete(url);
  }

  public getProjectLogo(projectCode: string) {
    return this.http.get(this.PROJECT_LOGO_URL.replace('#', projectCode));
  }

  public getCity() {
    return this.http.get(this.GET_CITY_URL);
  }
  //Get list of States
  public getStates() {
    return this.http.get(this.GET_STATE_URL);
  }

  public getCriteriaConfig() {
    return this.http.get(this.CRITERIA_CONFIG_URL);
  }

  updateProject(project: any, project_id: string) {
    const url = `${this.PROJECT_URL}/${project_id}`;
    return this.http.patch(url, project);
  }

  // uploadProjectLogo(file: File, projectId: string): Observable<any> {
  //   const formData: FormData = new FormData();
  //   formData.append('file', file);

  //   return this.http.post(this.PROJECT_URL_ID.replace('#', projectId), formData);
  // }

  getUploadUrl(projectId: string) {
    return this.http.post(this.PROJECT_URL_ID.replace('#', projectId), {  });
  }

  fileUploadToS3(uploadUrl: string, file: File) {
    return this.http.put(uploadUrl, file, {
      reportProgress: true,
      observe: 'events',
    });
  }
  
  updateProjectLogo(projectId: string, fileUrl: string) {
    return this.http.post(`${this.PROJECT_URL_ID.replace('#', projectId)}/save-logo`, { logoUrl: fileUrl });
  }

  getProjectSummary(projectCode: string) {
    return this.http.get(this.PROJECT_SUMMARY_URL.replace('#', projectCode));
  }

  uploadAttendeesList = (
    attendeesList: UploadDocModel,
    projectCode: string,
    file: any
  ): Observable<ResponsePacket<ProjectUrl>> => {
    return this.http
      .post<ResponsePacket<ProjectUrl>>(
        this.UPLOAD_CHECKLIST_DOC.replace('#', projectCode).replace(
          '{}',
          file.name
        ),
        attendeesList
      )
      .pipe(catchError(this.handleError));
  };

  uploadCheckList = (
    checkList: UploadDocModel,
    projectCode: string,
    file: any
  ): Observable<ResponsePacket<ProjectUrl>> => {
    return this.http
      .post<ResponsePacket<ProjectUrl>>(
        this.UPLOAD_ATTENDEES_LIST.replace('#', projectCode).replace(
          '{}',
          file.name
        ),
        checkList
      )
      .pipe(catchError(this.handleError));
  };

  getAttendeesList = (
    projectCode: string
  ): Observable<ResponsePacket<DownloadFile>> => {
    return this.http
      .get<ResponsePacket<DownloadFile>>(
        this.GET_ATTENDEES_REQUEST.replace('{}', projectCode)
      )
      .pipe(catchError(this.handleError));
  };

  getHolidayList() {
    return this.http.get(this.HOLIDAY_LIST);
  }

  getCheckList = (
    projectCode: string
  ): Observable<ResponsePacket<DownloadFile>> => {
    return this.http
      .get<ResponsePacket<DownloadFile>>(
        this.GET_CHECKLIST_REQUEST.replace('{}', projectCode)
      )
      .pipe(catchError(this.handleError));
  };

  downloadDocList = (url: string): Observable<any> => {
    return this.http
      .get(url, { responseType: 'blob' })
      .pipe(catchError(this.handleError));
  };

  downloadCertificate = (project_code: string): Observable<any> => {
    return this.http
      .get(this.DOWNLOAD_CERTIFICATE_URL.replace('#', project_code))
      .pipe(catchError(this.handleError));
  };

  downloadFile(url: string): Observable<any> {
    // Set headers if needed, e.g., for authorization
    const headers = new HttpHeaders();

    // Make the HTTP GET request to the URL
    return this.http.get(url, {
      headers: headers,
      responseType: 'blob' as 'json', // Specify the response type as 'blob'
    });
  }

  uploadHolidayList = (): Observable<ResponsePacket<UploadUrl>> => {
    return this.http
      .post<ResponsePacket<UploadUrl>>(this.HOLIDAY_UPLOAD_SERVICE, {})
      .pipe(catchError(this.handleError));
  };

  importHoliday(file: File) {
    const formData: FormData = new FormData();
    formData.append('holidays', file, file.name);

    return this.http.post(this.HOLIDAY_UPLOAD_SERVICE, formData);
  }

  uploadFileToS3 = (fileUploadUrl: string, file: any) => {
    console.log(`Uploaf file ${file.name} over ${fileUploadUrl}`);
    const formData = new FormData();
    formData.append('file', file);
    const headers = new HttpHeaders({ 'Content-Type': file.type });
    const req = new HttpRequest('PUT', fileUploadUrl, file, {
      headers: headers,
    });
    return this.http.request(req);
  };

  getCriteriaData(project_id: string) {
    return this.http.get(this.GET_CRITERIA_DATA.replace('#', project_id));
  }

  public getProject = (
    id: string
  ): Observable<ResponsePacket<ProjectData[]>> => {
    return this.http
      .get<ResponsePacket<ProjectData[]>>(
        this.PROJECT_BY_ID_URL.replace('#', id)
      )
      .pipe(catchError(this.handleError));
  };

  private handleError = (error: any) => {
    let errorMessage = '';
    if (error?.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error?.error?.error;
    } else {
      // server-side error
      errorMessage = error?.error?.error;
    }
    return throwError(errorMessage);
  };
}
