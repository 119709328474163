import { Directive, ElementRef, Renderer2, AfterViewInit, HostListener } from '@angular/core';

@Directive({
  selector: '[appScrollShadow]'
})
export class ScrollShadowDirective implements AfterViewInit {

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit() {
    this.checkScroll();
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: Event) {
    this.checkScroll();
  }

  private checkScroll() {
    const element = this.el.nativeElement;
    const container = element.parentElement; // Reference to the nav-container
    const scrollLeft = element.scrollLeft;
    const maxScrollLeft = element.scrollWidth - element.clientWidth;
    // console.log(element,container,scrollLeft,maxScrollLeft);

    const leftShadow = container.querySelector('.nav-shadow-left');
    const rightShadow = container.querySelector('.nav-shadow-right');

    if (scrollLeft > 0) {
      this.renderer.addClass(leftShadow, 'visible-left');
    } else {
      this.renderer.removeClass(leftShadow, 'visible-left');
    }

    if (scrollLeft < maxScrollLeft) {
      this.renderer.addClass(rightShadow, 'visible-right');
    } else {
      this.renderer.removeClass(rightShadow, 'visible-right');
    }
  }
}
