<h2 mat-dialog-title class="border-bottom">Preview</h2>
<mat-dialog-content style="padding: 0;">
    <!-- <iframe [src]="safePdfUrl" width="100%" height="500px"></iframe> -->
    <!-- <object [data]="safePdfUrl" type="application/pdf" width="100%" height="500px">
        <p>Unable to display PDF file. <a [href]="safePdfUrl">Download</a> instead.</p>
      </object> -->
    <pdf-viewer [src]="data.pdfUrl" [render-text]="true" [original-size]="false"
        style="width: 100%; height: 100%;" [zoom]="zoom"></pdf-viewer>
</mat-dialog-content>
<mat-dialog-actions class="border-top">
    <mat-icon (click)="zoom = zoom + 0.1">zoom_in</mat-icon>
    <mat-icon (click)="zoom>1?zoom = zoom - 0.1:1">zoom_out</mat-icon>
    <button class="close-btn" mat-dialog-close>Close</button>
</mat-dialog-actions>